*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-width: 100%;
  max-height: 100%;
}

input,
textarea {
  -webkit-user-select: auto;
}

select {
  -webkit-appearance: none;
}

textarea {
  white-space: revert;
}

::-webkit-input-placeholder {
  color: unset;
}

::-moz-placeholder {
  color: unset;
}

:-ms-input-placeholder {
  color: unset;
}

::-ms-input-placeholder {
  color: unset;
}

::placeholder {
  color: unset;
}

blockquote, q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a, button {
  cursor: revert;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

@font-face {
  font-family: "Gotham-light";
  src: url("/fe-web/fonts/Gotham/GothamLight.otf") format("opentype");
}
@font-face {
  font-family: "Gotham-regular";
  src: url("/fe-web/fonts/Gotham/Gotham.otf") format("opentype");
}
@font-face {
  font-family: "Gotham-regular-italic";
  src: url("/fe-web/fonts/Gotham/GothamItalic.otf") format("opentype");
}
@font-face {
  font-family: "Gotham-medium";
  src: url("/fe-web/fonts/Gotham/GothamMedium.otf") format("opentype");
}
@font-face {
  font-family: "Gotham-bold";
  src: url("/fe-web/fonts/Gotham/GothamBold.otf") format("opentype");
}
@font-face {
  font-family: "Gotham-black";
  src: url("/fe-web/fonts/Gotham/GothamBlack.otf") format("opentype");
}
@font-face {
  font-family: "Gotham-ultra";
  src: url("/fe-web/fonts/Gotham/GothamUltra.otf") format("opentype");
}
@font-face {
  font-family: "Omnes-bold";
  src: url("/fe-web/fonts/Omnes/OmnesBold.otf") format("opentype");
}
@font-face {
  font-family: "Omnes-bold-italic";
  src: url("/fe-web/fonts/Omnes/OmnesBoldItalic.otf") format("opentype");
}
@font-face {
  font-family: "Omnes-semibold-italic";
  src: url("/fe-web/fonts/Omnes/OmnesSemiboldItalic.otf") format("opentype");
}
@font-face {
  font-family: "Omnes-regular";
  src: url("/fe-web/fonts/Omnes/OmnesRegular.otf") format("opentype");
}
@font-face {
  font-family: "Omnes-regular-italic";
  src: url("/fe-web/fonts/Omnes/OmnesRegularItalic.otf") format("opentype");
}
@font-face {
  font-family: "Omnes-medium";
  src: url("/fe-web/fonts/Omnes/OmnesMedium.otf") format("opentype");
}
@font-face {
  font-family: "Nexa";
  src: url("/fe-web/fonts/NexaBlack.woff") format("woff");
}
@font-face {
  font-family: "Comfortaa-bold";
  src: url("/fe-web/fonts/Comfortaa/Comfortaa-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Comfortaa-light";
  src: url("/fe-web/fonts/Comfortaa/Comfortaa-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Comfortaa-medium";
  src: url("/fe-web/fonts/Comfortaa/Comfortaa-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Comfortaa-regular";
  src: url("/fe-web/fonts/Comfortaa/Comfortaa-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Comfortaa-semibold";
  src: url("/fe-web/fonts/Comfortaa/Comfortaa-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "sofidel";
  src: url("/fe-web/fonts/icons/sofidel.eot?e2eukp");
  src: url("/fe-web/fonts/icons/sofidel.eot?e2eukp#iefix") format("embedded-opentype"), url("/fe-web/fonts/icons/sofidel.ttf?e2eukp") format("truetype"), url("/fe-web/fonts/icons/sofidel.woff?e2eukp") format("woff"), url("/fe-web/fonts/icons/sofidel.svg?e2eukp#reckitt") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  font-family: "sofidel" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alert:before {
  content: "\e900";
}

.icon-arrow-drop-down:before {
  content: "\e901";
}

.icon-arrow-left:before {
  content: "\e902";
}

.icon-arrow-right:before {
  content: "\e903";
}

.icon-close:before {
  content: "\e905";
}

.icon-coin:before {
  content: "\e906";
}

.icon-external-link:before {
  content: "\e907";
}

.icon-eye:before {
  content: "\e908";
}

.icon-facebook:before {
  content: "\e909";
}

.icon-instagram:before {
  content: "\e90a";
}

.icon-prize:before {
  content: "\e910";
}

.icon-receipt:before {
  content: "\e90d";
}

.icon-unlock:before {
  content: "\e90e";
}

.icon-user:before {
  content: "\e90f";
}

.icon-check:before {
  content: "\e904";
}

.icon-back:before {
  content: "\e90b";
}

.icon-eye-off:before {
  content: "\e910";
}

.icon-logout:before {
  content: "\e911";
}

.icon-send-message:before {
  content: "\e912";
}

.icon-play:before {
  content: "\e913";
}

.icon-plus:before {
  content: "\e914";
}

.icon-minus:before {
  content: "\e915";
}

.icon-youtube:before {
  content: "\ea9d";
}

.icon-tiktok:before {
  content: "\e900";
}

/** MEDIA QUERY **/
.ty-receipt-invoice__button, .load-receipt-invoice a.cta, .yxp-modal .yxp-modal-button.save, .yxp-modal .yxp-modal-button.cancel, .prizes-request__button, .digital-rewards__button, .yxp-coupon-cta-wrapper a, .visual-component__link, .my-activities__no-results a, .personal-area-header__logout, .personal-area-header.red-version .personal-area-header__logout, .header__login, .yxpType_FORM .yxpSubmitBtn,
.yxpType_FORM .yxpNextBtn,
.yxp-rw-step .yxpSubmitBtn,
.yxp-rw-step .yxpNextBtn,
.yxp-modal .yxpSubmitBtn,
.yxp-modal .yxpNextBtn, .comfortaa .yxpType_FORM .yxpSubmitBtn,
.comfortaa .yxpType_FORM .yxpNextBtn,
.comfortaa .yxp-rw-step .yxpSubmitBtn,
.comfortaa .yxp-rw-step .yxpNextBtn,
.comfortaa .yxp-modal .yxpSubmitBtn,
.comfortaa .yxp-modal .yxpNextBtn, .green-theme .yxpType_FORM .yxpSubmitBtn,
.green-theme .yxpType_FORM .yxpNextBtn,
.green-theme .yxp-rw-step .yxpSubmitBtn,
.green-theme .yxp-rw-step .yxpNextBtn,
.green-theme .yxp-modal .yxpSubmitBtn,
.green-theme .yxp-modal .yxpNextBtn, .primary-button a, .primary-button-blue a, .primary-button-medium a, .primary-button-small a, .primary-button-small-blue a, .primary-button-medium-blue a, .secondary-button a, .secondary-button-medium a, .secondary-button-small a, .tertiary-button-white a, .tertiary-button a, .tertiary-button-medium a, .tertiary-button-small a, .primary-button-red a, .primary-button-yellow a, .primary-button-yellow-medium a, .primary-button-yellow-large a, .secondary-button-yellow a, .secondary-button-yellow-small a, .primary-button-green a, .primary-button-green-inverted a, .primary-button-azure a, .primary-button-pink a, .primary-button-blue-sky a, .secondary-button-black a, .secondary-button-green a {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  text-decoration: none;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  cursor: pointer;
  padding: 0 30px;
  border-radius: 40px;
  line-height: 1;
}
.ty-receipt-invoice__button:hover, .load-receipt-invoice a.cta:hover, .yxp-modal .yxp-modal-button.save:hover, .yxp-modal .yxp-modal-button.cancel:hover, .prizes-request__button:hover, .digital-rewards__button:hover, .yxp-coupon-cta-wrapper a:hover, .visual-component__link:hover, .my-activities__no-results a:hover, .personal-area-header__logout:hover, .personal-area-header.red-version .personal-area-header__logout:hover, .header__login:hover, .yxpType_FORM .yxpSubmitBtn:hover,
.yxpType_FORM .yxpNextBtn:hover,
.yxp-rw-step .yxpSubmitBtn:hover,
.yxp-rw-step .yxpNextBtn:hover,
.yxp-modal .yxpSubmitBtn:hover,
.yxp-modal .yxpNextBtn:hover, .primary-button a:hover, .primary-button-blue a:hover, .primary-button-medium a:hover, .primary-button-small a:hover, .primary-button-small-blue a:hover, .primary-button-medium-blue a:hover, .secondary-button a:hover, .secondary-button-medium a:hover, .secondary-button-small a:hover, .tertiary-button-white a:hover, .tertiary-button a:hover, .tertiary-button-medium a:hover, .tertiary-button-small a:hover, .primary-button-red a:hover, .primary-button-yellow a:hover, .primary-button-yellow-medium a:hover, .primary-button-yellow-large a:hover, .secondary-button-yellow a:hover, .secondary-button-yellow-small a:hover, .primary-button-green a:hover, .primary-button-green-inverted a:hover, .primary-button-azure a:hover, .primary-button-pink a:hover, .primary-button-blue-sky a:hover, .secondary-button-black a:hover, .secondary-button-green a:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  text-decoration: none;
}
.ty-receipt-invoice__button:disabled, .load-receipt-invoice a.cta:disabled, .yxp-modal .yxp-modal-button.save:disabled, .yxp-modal .yxp-modal-button.cancel:disabled, .prizes-request__button:disabled, .digital-rewards__button:disabled, .yxp-coupon-cta-wrapper a:disabled, .visual-component__link:disabled, .my-activities__no-results a:disabled, .personal-area-header__logout:disabled, .personal-area-header.red-version .personal-area-header__logout:disabled, .header__login:disabled, .yxpType_FORM .yxpSubmitBtn:disabled,
.yxpType_FORM .yxpNextBtn:disabled,
.yxp-rw-step .yxpSubmitBtn:disabled,
.yxp-rw-step .yxpNextBtn:disabled,
.yxp-modal .yxpSubmitBtn:disabled,
.yxp-modal .yxpNextBtn:disabled, .primary-button a:disabled, .primary-button-blue a:disabled, .primary-button-medium a:disabled, .primary-button-small a:disabled, .primary-button-small-blue a:disabled, .primary-button-medium-blue a:disabled, .secondary-button a:disabled, .secondary-button-medium a:disabled, .secondary-button-small a:disabled, .tertiary-button-white a:disabled, .tertiary-button a:disabled, .tertiary-button-medium a:disabled, .tertiary-button-small a:disabled, .primary-button-red a:disabled, .primary-button-yellow a:disabled, .primary-button-yellow-medium a:disabled, .primary-button-yellow-large a:disabled, .secondary-button-yellow a:disabled, .secondary-button-yellow-small a:disabled, .primary-button-green a:disabled, .primary-button-green-inverted a:disabled, .primary-button-azure a:disabled, .primary-button-pink a:disabled, .primary-button-blue-sky a:disabled, .secondary-button-black a:disabled, .secondary-button-green a:disabled, [disabled].ty-receipt-invoice__button, .load-receipt-invoice a[disabled].cta, .yxp-modal [disabled].yxp-modal-button.save, .yxp-modal [disabled].yxp-modal-button.cancel, [disabled].prizes-request__button, [disabled].digital-rewards__button, .yxp-coupon-cta-wrapper a[disabled], [disabled].visual-component__link, .my-activities__no-results a[disabled], [disabled].personal-area-header__logout, .personal-area-header.red-version [disabled].personal-area-header__logout, [disabled].header__login, .yxpType_FORM [disabled].yxpSubmitBtn,
.yxpType_FORM [disabled].yxpNextBtn,
.yxp-rw-step [disabled].yxpSubmitBtn,
.yxp-rw-step [disabled].yxpNextBtn,
.yxp-modal [disabled].yxpSubmitBtn,
.yxp-modal [disabled].yxpNextBtn, .primary-button a[disabled], .primary-button-blue a[disabled], .primary-button-medium a[disabled], .primary-button-small a[disabled], .primary-button-small-blue a[disabled], .primary-button-medium-blue a[disabled], .secondary-button a[disabled], .secondary-button-medium a[disabled], .secondary-button-small a[disabled], .tertiary-button-white a[disabled], .tertiary-button a[disabled], .tertiary-button-medium a[disabled], .tertiary-button-small a[disabled], .primary-button-red a[disabled], .primary-button-yellow a[disabled], .primary-button-yellow-medium a[disabled], .primary-button-yellow-large a[disabled], .secondary-button-yellow a[disabled], .secondary-button-yellow-small a[disabled], .primary-button-green a[disabled], .primary-button-green-inverted a[disabled], .primary-button-azure a[disabled], .primary-button-pink a[disabled], .primary-button-blue-sky a[disabled], .secondary-button-black a[disabled], .secondary-button-green a[disabled] {
  background: #999999;
  pointer-events: none;
  -webkit-transform: none;
          transform: none;
  cursor: default;
}

/** UTILS **/
.grecaptcha-badge {
  z-index: 10;
}

html {
  scroll-padding-top: 100px;
}

body {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #03499A;
  margin: 0;
}
body b, body strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa body {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa body b, .comfortaa body strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  body {
    font-size: 21px;
    line-height: 28px;
  }
}
body .comfortaa {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
  font-size: 16px;
  line-height: 22px;
}
body .comfortaa b, body .comfortaa strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  body .comfortaa {
    font-size: 20px;
    line-height: 28px;
  }
}
body .omnes .yxpTitle {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
body .omnes {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
  font-size: 16px;
  line-height: 22px;
}
@media (min-width: 768px) {
  body .omnes {
    font-size: 20px;
    line-height: 28px;
  }
}
body .black-color {
  color: #272727;
}
body .red-color {
  color: #DA291C !important;
}
body .dark-green {
  color: #007039 !important;
}
body .light-green {
  color: #61AB30 !important;
}
body .red-link a {
  color: #DA291C !important;
}
body .light-green-link a {
  color: #61AB30 !important;
}
body * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
body .yxpBlock {
  padding: 0;
}
body .yxpRow {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
@media (max-width: 767px) {
  body div[class*=yxp-cols-] {
    width: 100%;
  }
}
@media (max-width: 767px) {
  body div.yxp-cols-3 {
    width: 50%;
  }
}
body *[orange],
body .orange {
  color: #F47920;
}
body *[blue],
body .blue {
  color: #26337E;
}
body *[lightblue],
body .lightblue {
  color: #1C93DC;
}
body *[grey],
body .grey {
  color: #999999;
}
body *[white],
body .white {
  color: white !important;
}
body *[yellow],
body .yellow {
  color: #FFD019;
}
body *[green],
body .green {
  color: #34A50D;
}
body *[black-green],
body .black-green {
  color: #124C38;
}
body *[azure],
body .azure {
  color: #009FE3;
}
body a {
  color: inherit;
  text-decoration: none;
}
body a:hover {
  text-decoration: underline;
}
body a.orange {
  color: #F47920 !important;
}
body .yxpTitle {
  margin-bottom: 16px;
}
body .yxpTitle.yxpH1 {
  margin-bottom: 32px;
}
body .yxpHtmlTextWrapper {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  body .yxpHtmlTextWrapper {
    margin-bottom: 56px;
  }
}

h1 {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 52px;
  line-height: 46px;
}
h1 b, h1 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa h1 {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa h1 b, .comfortaa h1 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
h1 i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold-italic", sans-serif;
  font-size: 56px;
  line-height: 46px;
}
@media (min-width: 768px) {
  h1 {
    font-size: 70px;
    line-height: 56px;
  }
  h1 i {
    font-size: 78px;
    line-height: 46px;
  }
}
.nexa h1 {
  font-weight: normal;
  font-style: normal;
  font-family: "Nexa", sans-serif;
  line-height: 32px;
  font-size: 38px;
}
@media (min-width: 768px) {
  .nexa h1 {
    line-height: 62px;
    font-size: 52px;
  }
}

h2 {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 32px;
  line-height: 38px;
}
h2 b, h2 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa h2 {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa h2 b, .comfortaa h2 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
h2 i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold-italic", sans-serif;
  font-size: 32px;
  line-height: 34px;
}
@media (min-width: 768px) {
  h2 {
    font-size: 44px;
    line-height: 52px;
  }
  h2 i {
    font-size: 44px;
    line-height: 48px;
  }
}
.nexa h2 {
  font-weight: normal;
  font-style: normal;
  font-family: "Nexa", sans-serif;
  line-height: 26px;
  font-size: 22px;
}
@media (min-width: 768px) {
  .nexa h2 {
    line-height: 32px;
    font-size: 38px;
  }
}

h3 {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 32px;
  line-height: 38px;
}
h3 b, h3 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa h3 {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa h3 b, .comfortaa h3 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
h3 i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold-italic", sans-serif;
  font-size: 32px;
  line-height: 34px;
}
@media (min-width: 768px) {
  h3 {
    font-size: 40px;
    line-height: 46px;
  }
  h3 i {
    font-size: 44px;
    line-height: 46px;
  }
}
.nexa h3 {
  font-weight: normal;
  font-style: normal;
  font-family: "Nexa", sans-serif;
  line-height: 26px;
  font-size: 22px;
}

h4 {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 24px;
  line-height: 28px;
}
h4 b, h4 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa h4 {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa h4 b, .comfortaa h4 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
h4 i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold-italic", sans-serif;
  font-size: 25px;
  line-height: 28px;
}
@media (min-width: 768px) {
  h4 {
    font-size: 36px;
    line-height: 40px;
  }
  h4 i {
    font-size: 38px;
    line-height: 40px;
  }
}

h5 {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
}
h5 b, h5 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa h5 {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa h5 b, .comfortaa h5 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
h5 i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
@media (min-width: 768px) {
  h5 {
    font-size: 20px;
    line-height: 24px;
  }
}

h6, .text-small {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
}
h6 b, h6 strong, .text-small b, .text-small strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa h6, .comfortaa .text-small {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa h6 b, .comfortaa h6 strong, .comfortaa .text-small b, .comfortaa .text-small strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes h6, .omnes .text-small {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}

.text-small-omnes {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.text-large {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 20px;
  line-height: 28px;
}
.text-large b, .text-large strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .text-large {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .text-large b, .comfortaa .text-large strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .text-large {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
@media (min-width: 768px) {
  .text-large {
    font-size: 28px;
    line-height: 36px;
  }
}

.text-large-omnes {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
  font-size: 20px;
  line-height: 28px;
}
.text-large-omnes i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold-italic", sans-serif;
}
@media (min-width: 768px) {
  .text-large-omnes {
    font-size: 28px;
    line-height: 36px;
  }
}

.text-default {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
}
.text-default b, .text-default strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .text-default {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .text-default b, .comfortaa .text-default strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .text-default {
    font-size: 21px;
    line-height: 28px;
  }
}

.text-omnes {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
  font-size: 20px;
  line-height: 28px;
}

.caption {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 14px;
  line-height: 20px;
}
.caption b, .caption strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .caption {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .caption b, .comfortaa .caption strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .caption {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}

.caption-omnes {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.small-caption {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 12px;
  line-height: 16px;
}
.small-caption b, .small-caption strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .small-caption {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .small-caption b, .comfortaa .small-caption strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .small-caption {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}

.hidden-input {
  display: none !important;
}

.main-container {
  margin-top: 80px;
}
@media (min-width: 1024px) {
  .main-container {
    margin-top: 96px;
  }
}
.main-container > .yxpRow > .yxp-cols-12:not(.yxpType_CUSTOM) {
  padding: 60px 20px;
}
@media (min-width: 768px) {
  .main-container > .yxpRow > .yxp-cols-12:not(.yxpType_CUSTOM) {
    padding: 100px 20px;
  }
}

.points-chips-container > .yxpRow {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: 450px;
  margin: 16px auto 40px;
}
.points-chips-container > .yxpRow .yxpType_TEXT {
  white-space: nowrap;
}

.generic-card {
  background: white;
  border: 1px solid rgba(38, 51, 126, 0.1);
  -webkit-box-shadow: 0 8px 40px rgba(39, 39, 39, 0.1);
          box-shadow: 0 8px 40px rgba(39, 39, 39, 0.1);
  border-radius: 8px;
  text-align: center;
  padding: 16px 24px;
}
.generic-card.width-500 {
  max-width: 500px;
  margin: 0 auto;
}

.primary-button a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #F47920;
  font-size: 16px;
  color: white;
  border: none;
  height: 65px;
}
.comfortaa .primary-button a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .primary-button a {
    font-size: 21px;
  }
}

.primary-button-blue a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #03499A;
  font-size: 16px;
  color: white;
  border: none;
  height: 65px;
}
.comfortaa .primary-button-blue a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .primary-button-blue a {
    font-size: 21px;
  }
}

.primary-button-medium a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #F47920;
  font-size: 16px;
  color: white;
  border: none;
  height: 55px;
}
.comfortaa .primary-button-medium a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.primary-button-small a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #F47920;
  font-size: 16px;
  color: white;
  border: none;
  height: 48px;
}
.comfortaa .primary-button-small a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.primary-button-small-blue a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #03499A;
  font-size: 16px;
  color: white;
  border: none;
  height: 48px;
}
.comfortaa .primary-button-small-blue a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.primary-button-medium-blue a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #03499A;
  font-size: 16px;
  color: white;
  border: none;
  height: 55px;
}
.comfortaa .primary-button-medium-blue a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.secondary-button a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  background: white;
  color: #F47920;
  border: none;
  height: 65px;
}
.comfortaa .secondary-button a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.secondary-button-medium a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  background: white;
  color: #F47920;
  border: none;
  height: 55px;
}
.comfortaa .secondary-button-medium a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.secondary-button-small a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  background: white;
  color: #F47920;
  border: none;
  height: 48px;
}
.comfortaa .secondary-button-small a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.tertiary-button-white a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: white;
  font-size: 16px;
  color: white;
  border: none;
  height: 65px;
  color: #153E8C;
  border: none;
}
.comfortaa .tertiary-button-white a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .tertiary-button-white a {
    font-size: 21px;
  }
}

.tertiary-button a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  color: #26337E;
  border: #26337E 2px solid;
  height: 54px;
}
.comfortaa .tertiary-button a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .tertiary-button a {
    font-size: 21px;
  }
}

.tertiary-button-medium a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  color: #26337E;
  border: #26337E 2px solid;
  height: 50px;
}
.comfortaa .tertiary-button-medium a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.tertiary-button-small a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  color: #26337E;
  border: #26337E 2px solid;
  height: 48px;
}
.comfortaa .tertiary-button-small a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.primary-button-red a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #DA291C;
  font-size: 16px;
  color: white;
  border: none;
  height: 48px;
}
.comfortaa .primary-button-red a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.primary-button-yellow a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #FFD019;
  font-size: 16px;
  color: white;
  border: none;
  height: 48px;
  color: #153E8C;
  text-transform: none;
}
.comfortaa .primary-button-yellow a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.primary-button-yellow-medium a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #FFD019;
  font-size: 16px;
  color: white;
  border: none;
  height: 55px;
  color: #153E8C;
}
.comfortaa .primary-button-yellow-medium a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.primary-button-yellow-large a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #FFD019;
  font-size: 16px;
  color: white;
  border: none;
  height: 65px;
  color: #153E8C;
}
.comfortaa .primary-button-yellow-large a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .primary-button-yellow-large a {
    font-size: 21px;
  }
}

.secondary-button-yellow a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #FFD019;
  font-size: 16px;
  color: white;
  border: none;
  height: 65px;
}
.comfortaa .secondary-button-yellow a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .secondary-button-yellow a {
    font-size: 21px;
  }
}

.secondary-button-yellow-small a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #FFD019;
  font-size: 16px;
  color: white;
  border: none;
  height: 48px;
}
.comfortaa .secondary-button-yellow-small a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.primary-button-green a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #61AB30;
  font-size: 16px;
  color: white;
  border: none;
  height: 48px;
  color: white;
  text-transform: none;
  border-radius: 0;
}
.comfortaa .primary-button-green a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.primary-button-green-inverted a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: white;
  font-size: 16px;
  color: white;
  border: none;
  height: 48px;
  color: #007039;
  text-transform: none;
  border-radius: 0;
}
.comfortaa .primary-button-green-inverted a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.primary-button-azure a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #009FE3;
  font-size: 16px;
  color: white;
  border: none;
  height: 65px;
}
.comfortaa .primary-button-azure a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .primary-button-azure a {
    font-size: 21px;
  }
}

.primary-button-pink a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #E0007A;
  font-size: 16px;
  color: white;
  border: none;
  height: 65px;
}
.comfortaa .primary-button-pink a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .primary-button-pink a {
    font-size: 21px;
  }
}

.primary-button-blue-sky a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #98E1F6;
  font-size: 16px;
  color: white;
  border: none;
  height: 65px;
  color: #153E8C;
}
.comfortaa .primary-button-blue-sky a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .primary-button-blue-sky a {
    font-size: 21px;
  }
}

.secondary-button-black a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  background: white;
  color: #272727;
  border: none;
  height: 48px;
}
.comfortaa .secondary-button-black a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.secondary-button-green a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  background: white;
  color: #34A50D;
  border: none;
  height: 55px;
}
.comfortaa .secondary-button-green a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.squared-button a {
  border-radius: 8px !important;
  padding: 0 24px;
}

.link-underline {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: none;
  position: relative;
  padding: 4px 0;
  color: #F47920;
}
.link-underline b, .link-underline strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .link-underline {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .link-underline b, .comfortaa .link-underline strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .link-underline {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}
.link-underline:hover {
  text-decoration: none;
}
.link-underline::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#F47920), to(#F47920));
  background-image: linear-gradient(#F47920, #F47920);
  -webkit-transition: background-size 0.2s;
  transition: background-size 0.2s;
  background-repeat: no-repeat;
  background-size: 0 2px;
  background-position: right bottom;
  border-radius: 2px;
}
.link-underline:hover::before {
  background-size: 100% 2px;
  background-position: left bottom;
}
.link-underline-blue {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: none;
  position: relative;
  padding: 4px 0;
  color: #26337E;
}
.link-underline-blue b, .link-underline-blue strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .link-underline-blue {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .link-underline-blue b, .comfortaa .link-underline-blue strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .link-underline-blue {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}
.link-underline-blue:hover {
  text-decoration: none;
}
.link-underline-blue::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#26337E), to(#26337E));
  background-image: linear-gradient(#26337E, #26337E);
  -webkit-transition: background-size 0.2s;
  transition: background-size 0.2s;
  background-repeat: no-repeat;
  background-size: 0 2px;
  background-position: right bottom;
  border-radius: 2px;
}
.link-underline-blue:hover::before {
  background-size: 100% 2px;
  background-position: left bottom;
}
.link-underline-external-icon {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: none;
  position: relative;
  padding: 4px 0;
  color: #26337E;
  margin-right: 30px;
}
.link-underline-external-icon b, .link-underline-external-icon strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .link-underline-external-icon {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .link-underline-external-icon b, .comfortaa .link-underline-external-icon strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .link-underline-external-icon {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}
.link-underline-external-icon:hover {
  text-decoration: none;
}
.link-underline-external-icon::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#26337E), to(#26337E));
  background-image: linear-gradient(#26337E, #26337E);
  -webkit-transition: background-size 0.2s;
  transition: background-size 0.2s;
  background-repeat: no-repeat;
  background-size: 0 2px;
  background-position: right bottom;
  border-radius: 2px;
}
.link-underline-external-icon:hover::before {
  background-size: 100% 2px;
  background-position: left bottom;
}
.link-underline-external-icon::after {
  content: "\e907";
  font-family: "sofidel" !important;
  font-size: 24px;
  margin-left: 4px;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 100%;
  top: 50%;
}

.orange-link a {
  color: #F47920 !important;
}

.pound-chips {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
  line-height: 1;
  color: #1C93DC;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 28px;
}
.pound-chips i {
  padding-right: 6px;
}
.pound-chips::before {
  content: "";
  margin: 0 8px;
  width: 40px;
  height: 40px;
  background: url("/fe-web/img/sofidel/icons/pound.png") no-repeat center;
  background-size: contain;
}
.pound-chips span {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.pound-chips i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-semibold-italic", sans-serif;
  font-size: 16px;
  color: #26337E;
}

.pound-chips-inverted {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
  line-height: 1;
  color: #1C93DC;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 28px;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.pound-chips-inverted i {
  padding-left: 6px;
}
.pound-chips-inverted::before {
  content: "";
  margin: 0 8px;
  width: 40px;
  height: 40px;
  background: url("/fe-web/img/sofidel/icons/pound.png") no-repeat center;
  background-size: contain;
}
.pound-chips-inverted span {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.pound-chips-inverted i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-semibold-italic", sans-serif;
  font-size: 16px;
  color: #26337E;
}

.pound-chips-small {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
  line-height: 1;
  color: #1C93DC;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 28px;
  font-size: 20px;
}
.pound-chips-small i {
  padding-right: 6px;
}
.pound-chips-small::before {
  content: "";
  margin: 0 8px;
  width: 40px;
  height: 40px;
  background: url("/fe-web/img/sofidel/icons/pound.png") no-repeat center;
  background-size: contain;
}
.pound-chips-small span {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.pound-chips-small i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-semibold-italic", sans-serif;
  font-size: 16px;
  color: #26337E;
}
.pound-chips-small::before {
  width: 24px;
  height: 24px;
}

.pound-chips-small-inverted {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
  line-height: 1;
  color: #1C93DC;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 28px;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  font-size: 20px;
}
.pound-chips-small-inverted i {
  padding-left: 6px;
}
.pound-chips-small-inverted::before {
  content: "";
  margin: 0 8px;
  width: 40px;
  height: 40px;
  background: url("/fe-web/img/sofidel/icons/pound.png") no-repeat center;
  background-size: contain;
}
.pound-chips-small-inverted span {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.pound-chips-small-inverted i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-semibold-italic", sans-serif;
  font-size: 16px;
  color: #26337E;
}
.pound-chips-small-inverted::before {
  width: 24px;
  height: 24px;
}

.point-chips {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
  line-height: 1;
  color: #F47920;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 28px;
}
.point-chips i {
  padding-right: 6px;
}
.point-chips::before {
  content: "";
  margin: 0 8px;
  width: 40px;
  height: 40px;
  background: url("/fe-web/img/sofidel/icons/point.png") no-repeat center;
  background-size: contain;
}
.point-chips span {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.point-chips i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-semibold-italic", sans-serif;
  font-size: 16px;
  color: #26337E;
}

.point-chips-inverted {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
  line-height: 1;
  color: #F47920;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 28px;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.point-chips-inverted i {
  padding-left: 6px;
}
.point-chips-inverted::before {
  content: "";
  margin: 0 8px;
  width: 40px;
  height: 40px;
  background: url("/fe-web/img/sofidel/icons/point.png") no-repeat center;
  background-size: contain;
}
.point-chips-inverted span {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.point-chips-inverted i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-semibold-italic", sans-serif;
  font-size: 16px;
  color: #26337E;
}

.point-chips-small {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
  line-height: 1;
  color: #F47920;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 28px;
  font-size: 20px;
}
.point-chips-small i {
  padding-right: 6px;
}
.point-chips-small::before {
  content: "";
  margin: 0 8px;
  width: 40px;
  height: 40px;
  background: url("/fe-web/img/sofidel/icons/point.png") no-repeat center;
  background-size: contain;
}
.point-chips-small span {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.point-chips-small i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-semibold-italic", sans-serif;
  font-size: 16px;
  color: #26337E;
}
.point-chips-small::before {
  width: 24px;
  height: 24px;
}

.point-chips-small-inverted {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
  line-height: 1;
  color: #F47920;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 28px;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  font-size: 20px;
}
.point-chips-small-inverted i {
  padding-left: 6px;
}
.point-chips-small-inverted::before {
  content: "";
  margin: 0 8px;
  width: 40px;
  height: 40px;
  background: url("/fe-web/img/sofidel/icons/point.png") no-repeat center;
  background-size: contain;
}
.point-chips-small-inverted span {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.point-chips-small-inverted i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-semibold-italic", sans-serif;
  font-size: 16px;
  color: #26337E;
}
.point-chips-small-inverted::before {
  width: 24px;
  height: 24px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.yxpHidden,
.hidden {
  display: none;
}

@media (max-width: 767px) {
  .hide-mobile {
    display: none;
  }
}

@media (min-width: 768px) {
  .hide-desktop {
    display: none;
  }
}

.width-1700 > .yxpRow {
  max-width: 1700px !important;
  margin: 0 auto;
}

.width-1440 > .yxpRow {
  max-width: 1440px !important;
  margin: 0 auto;
}

.width-1280 > .yxpRow {
  max-width: 1280px !important;
  margin: 0 auto;
}

.width-1200 > .yxpRow {
  max-width: 1200px !important;
  margin: 0 auto;
}

.width-1024 > .yxpRow {
  max-width: 1024px !important;
  margin: 0 auto;
}

.width-900 > .yxpRow {
  max-width: 900px !important;
  margin: 0 auto;
}

.width-800 > .yxpRow {
  max-width: 800px !important;
  margin: 0 auto;
}

.width-600 > .yxpRow {
  max-width: 600px !important;
  margin: 0 auto;
}

.width-500 > .yxpRow {
  max-width: 500px !important;
  margin: 0 auto;
}

.background-with-image {
  padding: 180px 20px !important;
}

.blue-gradient {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(2%, #1C93DC), color-stop(50%, rgba(1, 233, 189, 0.5)), to(white)) !important;
  background: linear-gradient(180deg, #1C93DC 2%, rgba(1, 233, 189, 0.5) 50%, white 100%) !important;
}

.blue-gradient-inverted {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(-50%, #1C93DC), color-stop(40%, rgba(1, 233, 189, 0.5)), color-stop(80%, white)) !important;
  background: linear-gradient(0deg, #1C93DC -50%, rgba(1, 233, 189, 0.5) 40%, white 80%) !important;
}

.blue-gradient-wave {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(2%, #1C93DC), color-stop(50%, rgba(1, 233, 189, 0.5)), to(white)) !important;
  background: linear-gradient(180deg, #1C93DC 2%, rgba(1, 233, 189, 0.5) 50%, white 100%) !important;
  padding-top: 100px;
  position: relative;
}
@media (min-width: 768px) {
  .blue-gradient-wave {
    padding-top: 150px !important;
  }
}
.blue-gradient-wave:before {
  content: "";
  position: absolute;
  background: url("/fe-web/img/sofidel/backgrounds/white-wave.png") no-repeat top;
  background-size: cover;
  height: 30px;
  width: 100%;
  top: -1px;
  left: 0;
}
@media (min-width: 768px) {
  .blue-gradient-wave:before {
    background-size: contain;
    height: 4vw;
  }
}
.blue-gradient-wave.flipped:before {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.blue-gradient-full {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-5%, #1C93DC), to(rgba(1, 233, 189, 0.5))) !important;
  background: linear-gradient(180deg, #1C93DC -5%, rgba(1, 233, 189, 0.5) 100%) !important;
}

.blue-gradient-full-wave {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-5%, #1C93DC), to(rgba(1, 233, 189, 0.5))) !important;
  background: linear-gradient(180deg, #1C93DC -5%, rgba(1, 233, 189, 0.5) 100%) !important;
  padding-top: 100px;
  position: relative;
}
@media (min-width: 768px) {
  .blue-gradient-full-wave {
    padding-top: 150px !important;
  }
}
.blue-gradient-full-wave:before {
  content: "";
  position: absolute;
  background: url("/fe-web/img/sofidel/backgrounds/white-wave.png") no-repeat top;
  background-size: cover;
  height: 30px;
  width: 100%;
  top: -1px;
  left: 0;
}
@media (min-width: 768px) {
  .blue-gradient-full-wave:before {
    background-size: contain;
    height: 4vw;
  }
}
.blue-gradient-full-wave.flipped:before {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.white-wave {
  padding-top: 100px;
  position: relative;
}
@media (min-width: 768px) {
  .white-wave {
    padding-top: 150px !important;
  }
}
.white-wave:before {
  content: "";
  position: absolute;
  background: url("/fe-web/img/sofidel/backgrounds/white-wave.png") no-repeat top;
  background-size: cover;
  height: 30px;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-transform: rotate(180deg) translateY(100%);
          transform: rotate(180deg) translateY(100%);
}
@media (min-width: 768px) {
  .white-wave:before {
    background-size: contain;
    height: 4vw;
  }
}
.white-wave.flipped:before {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.light-blue-gradient {
  background: linear-gradient(177.33deg, rgba(134, 229, 253, 0.3) 10.02%, rgba(47, 197, 235, 0.3) 69.79%, rgba(152, 237, 229, 0.3) 113.77%), #FFFFFF !important;
}

.light-blue-gradient-wave {
  background: linear-gradient(177.33deg, rgba(134, 229, 253, 0.3) 10.02%, rgba(47, 197, 235, 0.3) 69.79%, rgba(152, 237, 229, 0.3) 113.77%), #FFFFFF !important;
  padding-top: 100px;
  position: relative;
}
@media (min-width: 768px) {
  .light-blue-gradient-wave {
    padding-top: 150px !important;
  }
}
.light-blue-gradient-wave:before {
  content: "";
  position: absolute;
  background: url("/fe-web/img/sofidel/backgrounds/white-wave.png") no-repeat top;
  background-size: cover;
  height: 30px;
  width: 100%;
  top: -1px;
  left: 0;
}
@media (min-width: 768px) {
  .light-blue-gradient-wave:before {
    background-size: contain;
    height: 4vw;
  }
}
.light-blue-gradient-wave.flipped:before {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.light-blue-gradient-wave.bubble {
  background: url("/fe-web/img/sofidel/backgrounds/bubble.png") no-repeat center bottom, linear-gradient(177.33deg, rgba(134, 229, 253, 0.3) 10.02%, rgba(47, 197, 235, 0.3) 69.79%, rgba(152, 237, 229, 0.3) 113.77%), #FFFFFF;
  background-size: auto 60%;
}
@media (min-width: 768px) {
  .light-blue-gradient-wave.bubble {
    background-size: 90%;
  }
}

.light-blue-gradient-wave-secondary {
  background: linear-gradient(177.33deg, rgba(134, 229, 253, 0.3) 10.02%, rgba(47, 197, 235, 0.3) 69.79%, rgba(152, 237, 229, 0.3) 113.77%), #FFFFFF;
  padding-top: 100px;
  position: relative;
}
@media (min-width: 768px) {
  .light-blue-gradient-wave-secondary {
    padding-top: 150px !important;
  }
}
.light-blue-gradient-wave-secondary:before {
  content: "";
  position: absolute;
  background: url("/fe-web/img/sofidel/backgrounds/top-blue-wave.png") no-repeat top;
  background-size: cover;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  height: 30px;
  width: 100%;
  top: 1px;
  left: 0;
}
@media (min-width: 768px) {
  .light-blue-gradient-wave-secondary:before {
    height: 5vw;
  }
}
.light-blue-gradient-wave-secondary.flipped:before {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.light-blue-gradient-wave-secondary.bubble {
  background: url("/fe-web/img/sofidel/backgrounds/bubble.png") no-repeat center bottom, linear-gradient(177.33deg, rgba(134, 229, 253, 0.3) 10.02%, rgba(47, 197, 235, 0.3) 69.79%, rgba(152, 237, 229, 0.3) 113.77%), #FFFFFF;
  background-size: auto 60%;
}
@media (min-width: 768px) {
  .light-blue-gradient-wave-secondary.bubble {
    background-size: 90%;
  }
}

.orange-gradient {
  background: linear-gradient(172.61deg, #F47920 2%, rgb(255, 218, 168) 40%, rgba(255, 200, 50, 0) 70%);
}

.orange-gradient-wave {
  background: linear-gradient(172.61deg, #F47920 2%, rgb(255, 218, 168) 40%, rgba(255, 200, 50, 0) 70%);
  padding-top: 100px;
  position: relative;
}
@media (min-width: 768px) {
  .orange-gradient-wave {
    padding-top: 150px !important;
  }
}
.orange-gradient-wave:before {
  content: "";
  position: absolute;
  background: url("/fe-web/img/sofidel/backgrounds/white-wave.png") no-repeat top;
  background-size: cover;
  height: 30px;
  width: 100%;
  top: -1px;
  left: 0;
}
@media (min-width: 768px) {
  .orange-gradient-wave:before {
    background-size: contain;
    height: 4vw;
  }
}
.orange-gradient-wave.flipped:before {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.purple-gradient-full-wave {
  background: linear-gradient(186.37deg, rgba(255, 255, 255, 0.09) -41.76%, #839DCC 94.9%);
  padding-top: 100px;
  position: relative;
}
@media (min-width: 768px) {
  .purple-gradient-full-wave {
    padding-top: 150px !important;
  }
}
.purple-gradient-full-wave:before {
  content: "";
  position: absolute;
  background: url("/fe-web/img/sofidel/backgrounds/white-wave.png") no-repeat top;
  background-size: cover;
  height: 30px;
  width: 100%;
  top: -1px;
  left: 0;
}
@media (min-width: 768px) {
  .purple-gradient-full-wave:before {
    background-size: contain;
    height: 4vw;
  }
}
.purple-gradient-full-wave.flipped:before {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.navy-blue-wave {
  padding-top: 100px;
  position: relative;
}
@media (min-width: 768px) {
  .navy-blue-wave {
    padding-top: 150px !important;
  }
}
.navy-blue-wave:before {
  content: "";
  position: absolute;
  background: url("/fe-web/img/sofidel/backgrounds/navy-blue-wave.png") no-repeat top;
  background-size: cover;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  height: 134px;
  width: 100%;
  top: 1px;
  left: 0;
}
@media (min-width: 768px) {
  .navy-blue-wave:before {
    height: 8vw;
  }
}

.navy-gradient-wave {
  padding-top: 100px;
  position: relative;
}
@media (min-width: 768px) {
  .navy-gradient-wave {
    padding-top: 150px !important;
  }
}
.navy-gradient-wave:before {
  content: "";
  position: absolute;
  background: url("/fe-web/img/sofidel/backgrounds/blue-wave.png") no-repeat top;
  background-size: cover;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  height: 30px;
  width: 100%;
  top: 1px;
  left: 0;
}
@media (min-width: 768px) {
  .navy-gradient-wave:before {
    height: 8vw;
  }
}

.with-height {
  min-height: 58vw;
}

.lightblue-coupon-bg {
  background: #009EE2 !important;
}

.red-background {
  background: #DA291C !important;
}

.light-green-background {
  background: #61AB30 !important;
}

.blue-violet-bg {
  background: #361D9C !important;
}

.white-background {
  background: white !important;
}

@media (max-width: 767px) {
  .background-contain-mobile {
    background-size: contain !important;
    background-repeat: no-repeat !important;
  }
}

.no-background {
  background: none !important;
}
.no-background:after, .no-background:before {
  background: none !important;
}

.no-wave:after,
.no-wave:before {
  background: none !important;
}

.loading::after,
.yxpLoading::after {
  content: "";
  border: 4px solid transparent;
  border-top-color: #F47920;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  position: fixed;
  z-index: 10;
  top: -webkit-calc(50% - 20px);
  top: calc(50% - 20px);
  left: -webkit-calc(50% - 20px);
  left: calc(50% - 20px);
}
.black-color .loading::after,
.black-color .yxpLoading::after {
  border-top-color: #007039;
}
.loading::before,
.yxpLoading::before {
  content: " ";
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
}

.transition-show > div {
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  opacity: 1;
}

.transition-hide > div {
  opacity: 0;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.swiper-pagination-bullet-active {
  background: #26337E;
  width: 30px;
  border-radius: 8px;
}

.info-card {
  text-align: left;
  border-radius: 8px;
  padding: 16px !important;
  border: 1px solid rgba(38, 51, 126, 0.2);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background: white;
}
.info-card a,
.info-card .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #26337E;
  text-decoration: none;
  cursor: default;
  margin: 0;
}
.info-card a b, .info-card a strong,
.info-card .yxpHtmlTextWrapper b,
.info-card .yxpHtmlTextWrapper strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .info-card a,
.comfortaa .info-card .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .info-card a b, .comfortaa .info-card a strong,
.comfortaa .info-card .yxpHtmlTextWrapper b,
.comfortaa .info-card .yxpHtmlTextWrapper strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .info-card a,
.omnes .info-card .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
.info-card:before {
  content: "\e900";
  font-family: "sofidel" !important;
  font-size: 40px;
  color: #F47920;
  margin-right: 16px;
}

.info-document {
  text-align: left;
  border-radius: 8px;
  padding: 16px !important;
  border: 1px solid rgba(38, 51, 126, 0.2);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background: white;
}
.info-document a,
.info-document .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #26337E;
  text-decoration: none;
  cursor: default;
  margin: 0;
}
.info-document a b, .info-document a strong,
.info-document .yxpHtmlTextWrapper b,
.info-document .yxpHtmlTextWrapper strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .info-document a,
.comfortaa .info-document .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .info-document a b, .comfortaa .info-document a strong,
.comfortaa .info-document .yxpHtmlTextWrapper b,
.comfortaa .info-document .yxpHtmlTextWrapper strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .info-document a,
.omnes .info-document .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
.info-document:before {
  content: "\e900";
  font-family: "sofidel" !important;
  font-size: 40px;
  color: #F47920;
  margin-right: 16px;
}
.info-document:before {
  content: url("/fe-web/img/sofidelcouponit/icons/document.png");
}

.no-underline a:hover {
  text-decoration: none;
}

.contact-form .yxpType_MSG_INPUT textarea {
  border: 2px solid #26337E;
  padding: 20px;
  border-radius: 8px;
}

.contact-ok .primary-button {
  text-align: center;
}

.p-25 {
  padding: 25px !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.py-0 {
  padding: 0 !important;
}
.py-25 {
  padding: 25px 0 !important;
}
.py-50 {
  padding: 50px 0 !important;
}
@media (max-width: 767px) {
  .py-50 {
    padding: 25px 0 !important;
  }
}
.py-100 {
  padding: 100px 0 !important;
}
.py-200 {
  padding: 200px 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-250 {
  padding-bottom: 250px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mb-0 {
  margin-bottom: 0;
}

.overlap-banner-wrapper {
  margin-top: -300px;
  z-index: 2;
}
.overlap-banner-wrapper .overlap-banner {
  border-radius: 30px;
}
@media (max-width: 767px) {
  .overlap-banner-wrapper .overlap-banner {
    padding: 70px !important;
  }
}

.banner-with-radius {
  border-radius: 30px;
  padding: 200px 0;
}
@media (max-width: 767px) {
  .banner-with-radius {
    padding: 70px;
  }
}

.play-code-page__background {
  height: 849px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 767px) {
  .play-code-page__background {
    height: 570px;
  }
}
@media (min-width: 768px) {
  .play-code-page h1 i {
    line-height: 86px;
  }
}
.play-code-page .yxp-lt-coupon-wrapper input[type=text] {
  background: white;
}
.play-code-page .yxp-lt-coupon-wrapper .ok-wrapper {
  color: white;
}

.info-uploads {
  text-align: left;
  border-radius: 8px;
  padding: 16px !important;
  border: 1px solid rgba(38, 51, 126, 0.2);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: white;
}
.info-uploads span {
  margin-left: 85px;
}
.info-uploads:before {
  content: url("/fe-web/img/sofidelcouponit/icons/document.png");
  position: absolute;
}

.no-space .yxpInputGroupLabelRight {
  white-space: nowrap;
}
@media (max-width: 767px) {
  .no-space .yxpInputGroupLabelRight {
    white-space: unset;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.yxp-modal-content {
  color: #03499A;
}
.yxp-modal-content .yxp-modal-description {
  margin-bottom: 30px;
}
.yxp-modal-content a {
  color: #03499A;
}

.minimal-template {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  min-height: 100vh;
}
.minimal-template b, .minimal-template strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .minimal-template {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .minimal-template b, .comfortaa .minimal-template strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.minimal-template i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
@media (min-width: 768px) {
  .minimal-template {
    font-size: 20px;
    line-height: 24px;
  }
}
.minimal-template .header {
  position: static;
}
.minimal-template .main-container {
  padding-top: 100px;
  position: relative;
  background: #009EE2;
  margin-top: 90px;
  min-height: -webkit-calc(100vh - 90px - 180px);
  min-height: calc(100vh - 90px - 180px);
}
@media (min-width: 768px) {
  .minimal-template .main-container {
    padding-top: 150px !important;
  }
}
.minimal-template .main-container:before {
  content: "";
  position: absolute;
  background: url("/fe-web/img/sofidel/backgrounds/white-wave.png") no-repeat top;
  background-size: cover;
  height: 30px;
  width: 100%;
  top: -1px;
  left: 0;
}
@media (min-width: 768px) {
  .minimal-template .main-container:before {
    background-size: contain;
    height: 4vw;
  }
}
.minimal-template .main-container.flipped:before {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
@media (min-width: 768px) {
  .minimal-template .main-container {
    margin-top: 180px;
    min-height: -webkit-calc(100vh - 180px - 180px);
    min-height: calc(100vh - 180px - 180px);
  }
}
.minimal-template .main-container.blue-background {
  background: #374E9C;
}
.minimal-template .back-link {
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 0;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.minimal-template .back-link:before {
  content: "\e90b";
  font-family: "sofidel" !important;
  font-size: 24px;
  color: #03499A;
  margin-right: 16px;
}
.black-color .minimal-template .back-link:before {
  color: #272727;
}
.orange-theme .minimal-template .back-link:before {
  color: #F47920;
}
.minimal-template .back-link a {
  color: #03499A;
  font-size: 20px;
}
.black-color .minimal-template .back-link a {
  color: #272727;
}
.orange-theme .minimal-template .back-link a {
  color: #F47920;
}
.minimal-template .generic-card {
  position: relative;
  width: -webkit-calc(100% - 40px);
  width: calc(100% - 40px);
  padding: 32px 16px;
  margin: -180px auto 120px;
  max-width: 900px;
  z-index: 10;
}
@media (min-width: 768px) {
  .minimal-template .generic-card {
    padding: 56px !important;
    margin-top: -280px;
    margin-bottom: 200px;
  }
}
.minimal-template .generic-card > .yxpRow:last-child .yxpHtmlTextWrapper {
  margin: 0;
}
.minimal-template .generic-card .yxpType_TEXTAREA a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  color: #03499A;
  font-size: inherit;
}
.black-color .minimal-template .generic-card .yxpType_TEXTAREA a {
  color: #272727;
}
.orange-theme .minimal-template .generic-card .yxpType_TEXTAREA a {
  color: #F47920;
}
.minimal-template .generic-card .yxpImageWrapper {
  margin-bottom: 16px;
}
.minimal-template .generic-card .hidden {
  display: none !important;
}
.minimal-template .generic-card form {
  margin-top: 24px;
}

.yxpType_FORM form .yxpRow .yxpInputGroup,
.yxp-rw-step form .yxpRow .yxpInputGroup,
.yxp-modal form .yxpRow .yxpInputGroup {
  padding: 0 0 32px;
  text-align: left;
}
.yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=password]), .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=oldPassword]), .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]),
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=password]),
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=oldPassword]),
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]),
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=password]),
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=oldPassword]),
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) {
  position: relative;
}
.yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=password]) input, .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) input, .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) input,
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=password]) input,
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) input,
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) input,
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=password]) input,
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) input,
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) input {
  padding-right: 42px;
}
.yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=password]) .visibility-icon, .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) .visibility-icon, .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) .visibility-icon,
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=password]) .visibility-icon,
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) .visibility-icon,
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) .visibility-icon,
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=password]) .visibility-icon,
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) .visibility-icon,
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) .visibility-icon {
  position: absolute;
  bottom: 32px;
  right: -12px;
  padding: 5px;
  width: 50px;
  line-height: 1;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  border: none;
}
.yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=password]) .visibility-icon:before, .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) .visibility-icon:before, .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) .visibility-icon:before,
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=password]) .visibility-icon:before,
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) .visibility-icon:before,
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) .visibility-icon:before,
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=password]) .visibility-icon:before,
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) .visibility-icon:before,
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) .visibility-icon:before {
  content: "\e908";
  font-family: "sofidel" !important;
  font-size: 40px;
  color: #03499A;
}
.black-color .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=password]) .visibility-icon:before, .black-color .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) .visibility-icon:before, .black-color .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) .visibility-icon:before,
.black-color .yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=password]) .visibility-icon:before,
.black-color .yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) .visibility-icon:before,
.black-color .yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) .visibility-icon:before,
.black-color .yxp-modal form .yxpRow .yxpInputGroup:has(input[name=password]) .visibility-icon:before,
.black-color .yxp-modal form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) .visibility-icon:before,
.black-color .yxp-modal form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) .visibility-icon:before {
  color: #272727;
}
.orange-theme .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=password]) .visibility-icon:before, .orange-theme .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) .visibility-icon:before, .orange-theme .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) .visibility-icon:before,
.orange-theme .yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=password]) .visibility-icon:before,
.orange-theme .yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) .visibility-icon:before,
.orange-theme .yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) .visibility-icon:before,
.orange-theme .yxp-modal form .yxpRow .yxpInputGroup:has(input[name=password]) .visibility-icon:before,
.orange-theme .yxp-modal form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) .visibility-icon:before,
.orange-theme .yxp-modal form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) .visibility-icon:before {
  color: #F47920;
}
.yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=password]) .visibility-icon:hover, .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) .visibility-icon:hover, .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) .visibility-icon:hover,
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=password]) .visibility-icon:hover,
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) .visibility-icon:hover,
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) .visibility-icon:hover,
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=password]) .visibility-icon:hover,
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=oldPassword]) .visibility-icon:hover,
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]) .visibility-icon:hover {
  background: rgba(153, 153, 153, 0.1);
}
.yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=password]).password-visible .visibility-icon:before, .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=oldPassword]).password-visible .visibility-icon:before, .yxpType_FORM form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]).password-visible .visibility-icon:before,
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=password]).password-visible .visibility-icon:before,
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=oldPassword]).password-visible .visibility-icon:before,
.yxp-rw-step form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]).password-visible .visibility-icon:before,
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=password]).password-visible .visibility-icon:before,
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=oldPassword]).password-visible .visibility-icon:before,
.yxp-modal form .yxpRow .yxpInputGroup:has(input[name=confirmPassword]).password-visible .visibility-icon:before {
  content: "\e910";
  font-family: "sofidel" !important;
  font-size: 24px;
  color: #999999;
}
.yxpType_FORM .yxpStepsWrapper,
.yxp-rw-step .yxpStepsWrapper,
.yxp-modal .yxpStepsWrapper {
  margin: 0;
}
.yxpType_FORM .yxpStepContainer.yxpStepActive,
.yxp-rw-step .yxpStepContainer.yxpStepActive,
.yxp-modal .yxpStepContainer.yxpStepActive {
  border: none;
}
.yxpType_FORM hr,
.yxp-rw-step hr,
.yxp-modal hr {
  border: none;
  border-bottom: 1px solid rgba(38, 51, 126, 0.2);
  margin-bottom: 16px;
}
.yxpType_FORM form .yxpRow,
.yxp-rw-step form .yxpRow,
.yxp-modal form .yxpRow {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  margin-bottom: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.yxpType_FORM form .yxpRow:has(.yxpInputGroupHidden),
.yxp-rw-step form .yxpRow:has(.yxpInputGroupHidden),
.yxp-modal form .yxpRow:has(.yxpInputGroupHidden) {
  display: none;
}
.yxpType_FORM form .yxpHtmlTextWrapper,
.yxp-rw-step form .yxpHtmlTextWrapper,
.yxp-modal form .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  margin-bottom: 16px;
  margin-top: -24px;
}
.yxpType_FORM form .yxpHtmlTextWrapper b, .yxpType_FORM form .yxpHtmlTextWrapper strong,
.yxp-rw-step form .yxpHtmlTextWrapper b,
.yxp-rw-step form .yxpHtmlTextWrapper strong,
.yxp-modal form .yxpHtmlTextWrapper b,
.yxp-modal form .yxpHtmlTextWrapper strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxpType_FORM form .yxpHtmlTextWrapper,
.comfortaa .yxp-rw-step form .yxpHtmlTextWrapper,
.comfortaa .yxp-modal form .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .yxpType_FORM form .yxpHtmlTextWrapper b, .comfortaa .yxpType_FORM form .yxpHtmlTextWrapper strong,
.comfortaa .yxp-rw-step form .yxpHtmlTextWrapper b,
.comfortaa .yxp-rw-step form .yxpHtmlTextWrapper strong,
.comfortaa .yxp-modal form .yxpHtmlTextWrapper b,
.comfortaa .yxp-modal form .yxpHtmlTextWrapper strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .yxpType_FORM form .yxpHtmlTextWrapper,
.omnes .yxp-rw-step form .yxpHtmlTextWrapper,
.omnes .yxp-modal form .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
@media (min-width: 768px) {
  .yxpType_FORM .yxp-cols-6,
  .yxp-rw-step .yxp-cols-6,
  .yxp-modal .yxp-cols-6 {
    width: 48%;
  }
}
.yxpType_FORM .yxpType_LINK,
.yxp-rw-step .yxpType_LINK,
.yxp-modal .yxpType_LINK {
  text-align: right;
}
.yxpType_FORM a,
.yxp-rw-step a,
.yxp-modal a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #03499A;
}
.yxpType_FORM a b, .yxpType_FORM a strong,
.yxp-rw-step a b,
.yxp-rw-step a strong,
.yxp-modal a b,
.yxp-modal a strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxpType_FORM a,
.comfortaa .yxp-rw-step a,
.comfortaa .yxp-modal a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .yxpType_FORM a b, .comfortaa .yxpType_FORM a strong,
.comfortaa .yxp-rw-step a b,
.comfortaa .yxp-rw-step a strong,
.comfortaa .yxp-modal a b,
.comfortaa .yxp-modal a strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.yxpType_FORM a i,
.yxp-rw-step a i,
.yxp-modal a i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
@media (min-width: 768px) {
  .yxpType_FORM a,
  .yxp-rw-step a,
  .yxp-modal a {
    font-size: 20px;
    line-height: 24px;
  }
}
.black-color .yxpType_FORM a,
.black-color .yxp-rw-step a,
.black-color .yxp-modal a {
  color: #272727;
}
.orange-theme .yxpType_FORM a,
.orange-theme .yxp-rw-step a,
.orange-theme .yxp-modal a {
  color: #F47920;
}
.yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel,
.yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel,
.yxp-modal .yxpInputGroup label.yxpInputGroupLabel {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #03499A;
  letter-spacing: 0;
  text-transform: none;
  padding: 0 0 4px 0;
}
.yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel b, .yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel strong,
.yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel b,
.yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel strong,
.yxp-modal .yxpInputGroup label.yxpInputGroupLabel b,
.yxp-modal .yxpInputGroup label.yxpInputGroupLabel strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel,
.comfortaa .yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel,
.comfortaa .yxp-modal .yxpInputGroup label.yxpInputGroupLabel {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel b, .comfortaa .yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel strong,
.comfortaa .yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel b,
.comfortaa .yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel strong,
.comfortaa .yxp-modal .yxpInputGroup label.yxpInputGroupLabel b,
.comfortaa .yxp-modal .yxpInputGroup label.yxpInputGroupLabel strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel,
  .yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel,
  .yxp-modal .yxpInputGroup label.yxpInputGroupLabel {
    font-size: 21px;
    line-height: 28px;
  }
}
.black-color .yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel,
.black-color .yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel,
.black-color .yxp-modal .yxpInputGroup label.yxpInputGroupLabel {
  color: #272727;
}
.orange-theme .yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel,
.orange-theme .yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel,
.orange-theme .yxp-modal .yxpInputGroup label.yxpInputGroupLabel {
  color: #F47920;
}
.yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight,
.yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight,
.yxp-modal .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #26337E;
  padding: 8px 24px;
  margin: 0;
}
.yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight b, .yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight strong,
.yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight b,
.yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight strong,
.yxp-modal .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight b,
.yxp-modal .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight,
.comfortaa .yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight,
.comfortaa .yxp-modal .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight b, .comfortaa .yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight strong,
.comfortaa .yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight b,
.comfortaa .yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight strong,
.comfortaa .yxp-modal .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight b,
.comfortaa .yxp-modal .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight,
.omnes .yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight,
.omnes .yxp-modal .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
.black-color .yxpType_FORM .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight,
.black-color .yxp-rw-step .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight,
.black-color .yxp-modal .yxpInputGroup label.yxpInputGroupLabel.yxpInputGroupLabelRight {
  color: #272727;
}
.yxpType_FORM .yxpInputGroup input,
.yxpType_FORM .yxpInputGroup select,
.yxpType_FORM .yxpInputGroup textarea,
.yxp-rw-step .yxpInputGroup input,
.yxp-rw-step .yxpInputGroup select,
.yxp-rw-step .yxpInputGroup textarea,
.yxp-modal .yxpInputGroup input,
.yxp-modal .yxpInputGroup select,
.yxp-modal .yxpInputGroup textarea {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #26337E;
  border: none;
  border-bottom: 2px solid #26337E;
  background: white;
  border-radius: 0;
  padding: 10px 0;
  height: auto;
  width: 100%;
}
.yxpType_FORM .yxpInputGroup input b, .yxpType_FORM .yxpInputGroup input strong,
.yxpType_FORM .yxpInputGroup select b,
.yxpType_FORM .yxpInputGroup select strong,
.yxpType_FORM .yxpInputGroup textarea b,
.yxpType_FORM .yxpInputGroup textarea strong,
.yxp-rw-step .yxpInputGroup input b,
.yxp-rw-step .yxpInputGroup input strong,
.yxp-rw-step .yxpInputGroup select b,
.yxp-rw-step .yxpInputGroup select strong,
.yxp-rw-step .yxpInputGroup textarea b,
.yxp-rw-step .yxpInputGroup textarea strong,
.yxp-modal .yxpInputGroup input b,
.yxp-modal .yxpInputGroup input strong,
.yxp-modal .yxpInputGroup select b,
.yxp-modal .yxpInputGroup select strong,
.yxp-modal .yxpInputGroup textarea b,
.yxp-modal .yxpInputGroup textarea strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxpType_FORM .yxpInputGroup input,
.comfortaa .yxpType_FORM .yxpInputGroup select,
.comfortaa .yxpType_FORM .yxpInputGroup textarea,
.comfortaa .yxp-rw-step .yxpInputGroup input,
.comfortaa .yxp-rw-step .yxpInputGroup select,
.comfortaa .yxp-rw-step .yxpInputGroup textarea,
.comfortaa .yxp-modal .yxpInputGroup input,
.comfortaa .yxp-modal .yxpInputGroup select,
.comfortaa .yxp-modal .yxpInputGroup textarea {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .yxpType_FORM .yxpInputGroup input b, .comfortaa .yxpType_FORM .yxpInputGroup input strong,
.comfortaa .yxpType_FORM .yxpInputGroup select b,
.comfortaa .yxpType_FORM .yxpInputGroup select strong,
.comfortaa .yxpType_FORM .yxpInputGroup textarea b,
.comfortaa .yxpType_FORM .yxpInputGroup textarea strong,
.comfortaa .yxp-rw-step .yxpInputGroup input b,
.comfortaa .yxp-rw-step .yxpInputGroup input strong,
.comfortaa .yxp-rw-step .yxpInputGroup select b,
.comfortaa .yxp-rw-step .yxpInputGroup select strong,
.comfortaa .yxp-rw-step .yxpInputGroup textarea b,
.comfortaa .yxp-rw-step .yxpInputGroup textarea strong,
.comfortaa .yxp-modal .yxpInputGroup input b,
.comfortaa .yxp-modal .yxpInputGroup input strong,
.comfortaa .yxp-modal .yxpInputGroup select b,
.comfortaa .yxp-modal .yxpInputGroup select strong,
.comfortaa .yxp-modal .yxpInputGroup textarea b,
.comfortaa .yxp-modal .yxpInputGroup textarea strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .yxpType_FORM .yxpInputGroup input,
  .yxpType_FORM .yxpInputGroup select,
  .yxpType_FORM .yxpInputGroup textarea,
  .yxp-rw-step .yxpInputGroup input,
  .yxp-rw-step .yxpInputGroup select,
  .yxp-rw-step .yxpInputGroup textarea,
  .yxp-modal .yxpInputGroup input,
  .yxp-modal .yxpInputGroup select,
  .yxp-modal .yxpInputGroup textarea {
    font-size: 21px;
    line-height: 28px;
  }
}
.black-color .yxpType_FORM .yxpInputGroup input,
.black-color .yxpType_FORM .yxpInputGroup select,
.black-color .yxpType_FORM .yxpInputGroup textarea,
.black-color .yxp-rw-step .yxpInputGroup input,
.black-color .yxp-rw-step .yxpInputGroup select,
.black-color .yxp-rw-step .yxpInputGroup textarea,
.black-color .yxp-modal .yxpInputGroup input,
.black-color .yxp-modal .yxpInputGroup select,
.black-color .yxp-modal .yxpInputGroup textarea {
  color: #272727;
  border-color: #272727;
}
.yxpType_FORM .yxpInputGroup input.yxp-error,
.yxpType_FORM .yxpInputGroup select.yxp-error,
.yxpType_FORM .yxpInputGroup textarea.yxp-error,
.yxp-rw-step .yxpInputGroup input.yxp-error,
.yxp-rw-step .yxpInputGroup select.yxp-error,
.yxp-rw-step .yxpInputGroup textarea.yxp-error,
.yxp-modal .yxpInputGroup input.yxp-error,
.yxp-modal .yxpInputGroup select.yxp-error,
.yxp-modal .yxpInputGroup textarea.yxp-error {
  border-color: #DE0000;
}
.yxpType_FORM .yxpInputGroup input:disabled,
.yxpType_FORM .yxpInputGroup select:disabled,
.yxpType_FORM .yxpInputGroup textarea:disabled,
.yxp-rw-step .yxpInputGroup input:disabled,
.yxp-rw-step .yxpInputGroup select:disabled,
.yxp-rw-step .yxpInputGroup textarea:disabled,
.yxp-modal .yxpInputGroup input:disabled,
.yxp-modal .yxpInputGroup select:disabled,
.yxp-modal .yxpInputGroup textarea:disabled {
  color: rgba(153, 153, 153, 0.6);
}
.yxpType_FORM ::-webkit-input-placeholder, .yxp-rw-step ::-webkit-input-placeholder, .yxp-modal ::-webkit-input-placeholder {
  color: #999999;
}
.yxpType_FORM ::-moz-placeholder, .yxp-rw-step ::-moz-placeholder, .yxp-modal ::-moz-placeholder {
  color: #999999;
}
.yxpType_FORM :-ms-input-placeholder, .yxp-rw-step :-ms-input-placeholder, .yxp-modal :-ms-input-placeholder {
  color: #999999;
}
.yxpType_FORM ::-ms-input-placeholder, .yxp-rw-step ::-ms-input-placeholder, .yxp-modal ::-ms-input-placeholder {
  color: #999999;
}
.yxpType_FORM ::placeholder,
.yxp-rw-step ::placeholder,
.yxp-modal ::placeholder {
  color: #999999;
}
.yxpType_FORM .yxpInputGroup select,
.yxp-rw-step .yxpInputGroup select,
.yxp-modal .yxpInputGroup select {
  background: url("/fe-web/img/sofidel/icons/select.png") no-repeat 99% center;
  background-size: 14px;
}
.yxpType_FORM .yxpRow .yxpInputDescription,
.yxp-rw-step .yxpRow .yxpInputDescription,
.yxp-modal .yxpRow .yxpInputDescription {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #26337E;
  margin-bottom: 8px;
  position: relative;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
}
.yxpType_FORM .yxpRow .yxpInputDescription b, .yxpType_FORM .yxpRow .yxpInputDescription strong,
.yxp-rw-step .yxpRow .yxpInputDescription b,
.yxp-rw-step .yxpRow .yxpInputDescription strong,
.yxp-modal .yxpRow .yxpInputDescription b,
.yxp-modal .yxpRow .yxpInputDescription strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxpType_FORM .yxpRow .yxpInputDescription,
.comfortaa .yxp-rw-step .yxpRow .yxpInputDescription,
.comfortaa .yxp-modal .yxpRow .yxpInputDescription {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .yxpType_FORM .yxpRow .yxpInputDescription b, .comfortaa .yxpType_FORM .yxpRow .yxpInputDescription strong,
.comfortaa .yxp-rw-step .yxpRow .yxpInputDescription b,
.comfortaa .yxp-rw-step .yxpRow .yxpInputDescription strong,
.comfortaa .yxp-modal .yxpRow .yxpInputDescription b,
.comfortaa .yxp-modal .yxpRow .yxpInputDescription strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .yxpType_FORM .yxpRow .yxpInputDescription,
.omnes .yxp-rw-step .yxpRow .yxpInputDescription,
.omnes .yxp-modal .yxpRow .yxpInputDescription {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
.black-color .yxpType_FORM .yxpRow .yxpInputDescription,
.black-color .yxp-rw-step .yxpRow .yxpInputDescription,
.black-color .yxp-modal .yxpRow .yxpInputDescription {
  color: #272727;
}
.yxpType_FORM .yxpFormActionsWrapper,
.yxp-rw-step .yxpFormActionsWrapper,
.yxp-modal .yxpFormActionsWrapper {
  margin: 0;
  text-align: center;
}
.yxpType_FORM .yxpFormActionsNavWrapper,
.yxp-rw-step .yxpFormActionsNavWrapper,
.yxp-modal .yxpFormActionsNavWrapper {
  float: none;
}
.yxpType_FORM .yxpSubmitBtn,
.yxpType_FORM .yxpNextBtn,
.yxp-rw-step .yxpSubmitBtn,
.yxp-rw-step .yxpNextBtn,
.yxp-modal .yxpSubmitBtn,
.yxp-modal .yxpNextBtn {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #03499A;
  font-size: 16px;
  color: white;
  border: none;
  height: 65px;
  margin: 32px 0 24px;
  width: auto;
  float: none;
}
.comfortaa .yxpType_FORM .yxpSubmitBtn,
.comfortaa .yxpType_FORM .yxpNextBtn,
.comfortaa .yxp-rw-step .yxpSubmitBtn,
.comfortaa .yxp-rw-step .yxpNextBtn,
.comfortaa .yxp-modal .yxpSubmitBtn,
.comfortaa .yxp-modal .yxpNextBtn {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .yxpType_FORM .yxpSubmitBtn,
  .yxpType_FORM .yxpNextBtn,
  .yxp-rw-step .yxpSubmitBtn,
  .yxp-rw-step .yxpNextBtn,
  .yxp-modal .yxpSubmitBtn,
  .yxp-modal .yxpNextBtn {
    font-size: 21px;
  }
}
.orange-theme .yxpType_FORM .yxpSubmitBtn,
.orange-theme .yxpType_FORM .yxpNextBtn,
.orange-theme .yxp-rw-step .yxpSubmitBtn,
.orange-theme .yxp-rw-step .yxpNextBtn,
.orange-theme .yxp-modal .yxpSubmitBtn,
.orange-theme .yxp-modal .yxpNextBtn {
  background: #F47920;
}
.comfortaa .yxpType_FORM .yxpSubmitBtn,
.comfortaa .yxpType_FORM .yxpNextBtn,
.comfortaa .yxp-rw-step .yxpSubmitBtn,
.comfortaa .yxp-rw-step .yxpNextBtn,
.comfortaa .yxp-modal .yxpSubmitBtn,
.comfortaa .yxp-modal .yxpNextBtn {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #DA291C;
  font-size: 16px;
  color: white;
  border: none;
  height: 55px;
  border-radius: 8px;
}
.comfortaa .comfortaa .yxpType_FORM .yxpSubmitBtn,
.comfortaa .comfortaa .yxpType_FORM .yxpNextBtn,
.comfortaa .comfortaa .yxp-rw-step .yxpSubmitBtn,
.comfortaa .comfortaa .yxp-rw-step .yxpNextBtn,
.comfortaa .comfortaa .yxp-modal .yxpSubmitBtn,
.comfortaa .comfortaa .yxp-modal .yxpNextBtn {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.green-theme .yxpType_FORM .yxpSubmitBtn,
.green-theme .yxpType_FORM .yxpNextBtn,
.green-theme .yxp-rw-step .yxpSubmitBtn,
.green-theme .yxp-rw-step .yxpNextBtn,
.green-theme .yxp-modal .yxpSubmitBtn,
.green-theme .yxp-modal .yxpNextBtn {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #007039;
  font-size: 16px;
  color: white;
  border: none;
  height: 55px;
  border-radius: 8px;
}
.comfortaa .green-theme .yxpType_FORM .yxpSubmitBtn,
.comfortaa .green-theme .yxpType_FORM .yxpNextBtn,
.comfortaa .green-theme .yxp-rw-step .yxpSubmitBtn,
.comfortaa .green-theme .yxp-rw-step .yxpNextBtn,
.comfortaa .green-theme .yxp-modal .yxpSubmitBtn,
.comfortaa .green-theme .yxp-modal .yxpNextBtn {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.yxpType_FORM .hidden,
.yxpType_FORM .yxpHidden,
.yxp-rw-step .hidden,
.yxp-rw-step .yxpHidden,
.yxp-modal .hidden,
.yxp-modal .yxpHidden {
  display: none !important;
}
.yxpType_FORM .yxpType_RADIO_INPUT label.yxpInputGroupLabel,
.yxp-rw-step .yxpType_RADIO_INPUT label.yxpInputGroupLabel,
.yxp-modal .yxpType_RADIO_INPUT label.yxpInputGroupLabel {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 20px;
  color: #26337E;
}
.black-color .yxpType_FORM .yxpType_RADIO_INPUT label.yxpInputGroupLabel,
.black-color .yxp-rw-step .yxpType_RADIO_INPUT label.yxpInputGroupLabel,
.black-color .yxp-modal .yxpType_RADIO_INPUT label.yxpInputGroupLabel {
  color: #272727;
}
.yxpType_FORM fieldset,
.yxp-rw-step fieldset,
.yxp-modal fieldset {
  padding: 12px 0 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.yxpType_FORM .yxpInputGroupRadioWrapper,
.yxp-rw-step .yxpInputGroupRadioWrapper,
.yxp-modal .yxpInputGroupRadioWrapper {
  margin-right: 16px;
  position: relative;
}
.yxpType_FORM .yxpInputGroupRadioWrapper::before,
.yxp-rw-step .yxpInputGroupRadioWrapper::before,
.yxp-modal .yxpInputGroupRadioWrapper::before {
  content: "";
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid rgba(38, 51, 126, 0.2);
  position: absolute;
  pointer-events: none;
  top: 6px;
  left: 0;
}
.yxpType_FORM .yxpInputGroupRadioWrapper::after,
.yxp-rw-step .yxpInputGroupRadioWrapper::after,
.yxp-modal .yxpInputGroupRadioWrapper::after {
  content: "";
  position: absolute;
  pointer-events: none;
  top: 10px;
  left: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #26337E;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.black-color .yxpType_FORM .yxpInputGroupRadioWrapper::after,
.black-color .yxp-rw-step .yxpInputGroupRadioWrapper::after,
.black-color .yxp-modal .yxpInputGroupRadioWrapper::after {
  background: #272727;
}
.yxpType_FORM .yxpInputGroupRadioWrapper[active=true]::before,
.yxp-rw-step .yxpInputGroupRadioWrapper[active=true]::before,
.yxp-modal .yxpInputGroupRadioWrapper[active=true]::before {
  border-color: #26337E;
}
.black-color .yxpType_FORM .yxpInputGroupRadioWrapper[active=true]::before,
.black-color .yxp-rw-step .yxpInputGroupRadioWrapper[active=true]::before,
.black-color .yxp-modal .yxpInputGroupRadioWrapper[active=true]::before {
  border-color: #272727;
}
.yxpType_FORM .yxpInputGroupRadioWrapper[active=true]::after,
.yxp-rw-step .yxpInputGroupRadioWrapper[active=true]::after,
.yxp-modal .yxpInputGroupRadioWrapper[active=true]::after {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.yxpType_FORM .yxpRow input[type=radio],
.yxp-rw-step .yxpRow input[type=radio],
.yxp-modal .yxpRow input[type=radio] {
  opacity: 0;
}
.yxpType_FORM .yxpInputCheckboxWrapper,
.yxp-rw-step .yxpInputCheckboxWrapper,
.yxp-modal .yxpInputCheckboxWrapper {
  background: none;
  border: none;
  padding: 0;
  margin: 8px 0;
}
.yxpType_FORM .yxpInputCheckboxWrapper .yxpInputGroupLabelRight,
.yxp-rw-step .yxpInputCheckboxWrapper .yxpInputGroupLabelRight,
.yxp-modal .yxpInputCheckboxWrapper .yxpInputGroupLabelRight {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  line-height: 23px;
  color: #26337E;
}
.yxpType_FORM .yxpInputCheckboxWrapper .yxpInputGroupLabelRight b, .yxpType_FORM .yxpInputCheckboxWrapper .yxpInputGroupLabelRight strong,
.yxp-rw-step .yxpInputCheckboxWrapper .yxpInputGroupLabelRight b,
.yxp-rw-step .yxpInputCheckboxWrapper .yxpInputGroupLabelRight strong,
.yxp-modal .yxpInputCheckboxWrapper .yxpInputGroupLabelRight b,
.yxp-modal .yxpInputCheckboxWrapper .yxpInputGroupLabelRight strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxpType_FORM .yxpInputCheckboxWrapper .yxpInputGroupLabelRight,
.comfortaa .yxp-rw-step .yxpInputCheckboxWrapper .yxpInputGroupLabelRight,
.comfortaa .yxp-modal .yxpInputCheckboxWrapper .yxpInputGroupLabelRight {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .yxpType_FORM .yxpInputCheckboxWrapper .yxpInputGroupLabelRight b, .comfortaa .yxpType_FORM .yxpInputCheckboxWrapper .yxpInputGroupLabelRight strong,
.comfortaa .yxp-rw-step .yxpInputCheckboxWrapper .yxpInputGroupLabelRight b,
.comfortaa .yxp-rw-step .yxpInputCheckboxWrapper .yxpInputGroupLabelRight strong,
.comfortaa .yxp-modal .yxpInputCheckboxWrapper .yxpInputGroupLabelRight b,
.comfortaa .yxp-modal .yxpInputCheckboxWrapper .yxpInputGroupLabelRight strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .yxpType_FORM .yxpInputCheckboxWrapper .yxpInputGroupLabelRight,
.omnes .yxp-rw-step .yxpInputCheckboxWrapper .yxpInputGroupLabelRight,
.omnes .yxp-modal .yxpInputCheckboxWrapper .yxpInputGroupLabelRight {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
.black-color .yxpType_FORM .yxpInputCheckboxWrapper .yxpInputGroupLabelRight,
.black-color .yxp-rw-step .yxpInputCheckboxWrapper .yxpInputGroupLabelRight,
.black-color .yxp-modal .yxpInputCheckboxWrapper .yxpInputGroupLabelRight {
  color: #272727;
}
.yxpType_FORM .yxpInputCheckboxWrapper .yxpInputGroupLabelRight a,
.yxp-rw-step .yxpInputCheckboxWrapper .yxpInputGroupLabelRight a,
.yxp-modal .yxpInputCheckboxWrapper .yxpInputGroupLabelRight a {
  font-size: inherit;
  color: #03499A;
  text-decoration: underline;
  margin-left: 3px;
}
.black-color .yxpType_FORM .yxpInputCheckboxWrapper .yxpInputGroupLabelRight a,
.black-color .yxp-rw-step .yxpInputCheckboxWrapper .yxpInputGroupLabelRight a,
.black-color .yxp-modal .yxpInputCheckboxWrapper .yxpInputGroupLabelRight a {
  color: #272727;
}
.orange-theme .yxpType_FORM .yxpInputCheckboxWrapper .yxpInputGroupLabelRight a,
.orange-theme .yxp-rw-step .yxpInputCheckboxWrapper .yxpInputGroupLabelRight a,
.orange-theme .yxp-modal .yxpInputCheckboxWrapper .yxpInputGroupLabelRight a {
  color: #F47920;
}
.yxpType_FORM .yxpInputCheckboxMultipleWrapper,
.yxpType_FORM span.yxpInputGroupLabelRight,
.yxp-rw-step .yxpInputCheckboxMultipleWrapper,
.yxp-rw-step span.yxpInputGroupLabelRight,
.yxp-modal .yxpInputCheckboxMultipleWrapper,
.yxp-modal span.yxpInputGroupLabelRight {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
}
.yxpType_FORM input[type=checkbox] + label::before,
.yxpType_FORM input[type=checkbox] + span::before,
.yxp-rw-step input[type=checkbox] + label::before,
.yxp-rw-step input[type=checkbox] + span::before,
.yxp-modal input[type=checkbox] + label::before,
.yxp-modal input[type=checkbox] + span::before {
  content: "\e904";
  font-family: "sofidel" !important;
  font-size: 0px;
  border-radius: 4px;
  border: 1px solid rgba(38, 51, 126, 0.2);
  pointer-events: none;
  -webkit-transition: font-size 0.2s;
  transition: font-size 0.2s;
  color: #26337E;
  margin-right: 16px;
  line-height: 22px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  text-align: center;
}
.black-color .yxpType_FORM input[type=checkbox] + label::before,
.black-color .yxpType_FORM input[type=checkbox] + span::before,
.black-color .yxp-rw-step input[type=checkbox] + label::before,
.black-color .yxp-rw-step input[type=checkbox] + span::before,
.black-color .yxp-modal input[type=checkbox] + label::before,
.black-color .yxp-modal input[type=checkbox] + span::before {
  color: #272727;
  border-color: #272727;
}
.yxpType_FORM input[type=checkbox]:checked + label::before,
.yxpType_FORM input[type=checkbox]:checked + span::before,
.yxp-rw-step input[type=checkbox]:checked + label::before,
.yxp-rw-step input[type=checkbox]:checked + span::before,
.yxp-modal input[type=checkbox]:checked + label::before,
.yxp-modal input[type=checkbox]:checked + span::before {
  border-color: #03499A;
  font-size: 12px;
}
.black-color .yxpType_FORM input[type=checkbox]:checked + label::before,
.black-color .yxpType_FORM input[type=checkbox]:checked + span::before,
.black-color .yxp-rw-step input[type=checkbox]:checked + label::before,
.black-color .yxp-rw-step input[type=checkbox]:checked + span::before,
.black-color .yxp-modal input[type=checkbox]:checked + label::before,
.black-color .yxp-modal input[type=checkbox]:checked + span::before {
  border-color: #272727;
}
.yxpType_FORM .yxpRow input[type=checkbox],
.yxp-rw-step .yxpRow input[type=checkbox],
.yxp-modal .yxpRow input[type=checkbox] {
  margin: 0;
  opacity: 0;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  min-width: 24px;
}
.yxpType_FORM .yxpPasswordValidator,
.yxp-rw-step .yxpPasswordValidator,
.yxp-modal .yxpPasswordValidator {
  display: none;
}
.yxpType_FORM .yxp-error,
.yxp-rw-step .yxp-error,
.yxp-modal .yxp-error {
  background: none;
}
.yxpType_FORM .yxp-error .yxpInputCheckboxMultipleWrapper:before,
.yxpType_FORM .yxp-error .yxpInputGroupRadioWrapper:before,
.yxp-rw-step .yxp-error .yxpInputCheckboxMultipleWrapper:before,
.yxp-rw-step .yxp-error .yxpInputGroupRadioWrapper:before,
.yxp-modal .yxp-error .yxpInputCheckboxMultipleWrapper:before,
.yxp-modal .yxp-error .yxpInputGroupRadioWrapper:before {
  border-color: #DE0000;
}
.yxpType_FORM .yxp-error.yxpInputGroup input, .yxpType_FORM .yxp-error.yxpInputGroup select, .yxpType_FORM .yxp-error.yxpInputGroup textarea,
.yxpType_FORM .yxp-error .yxpMultipleInputWrapper,
.yxp-rw-step .yxp-error.yxpInputGroup input,
.yxp-rw-step .yxp-error.yxpInputGroup select,
.yxp-rw-step .yxp-error.yxpInputGroup textarea,
.yxp-rw-step .yxp-error .yxpMultipleInputWrapper,
.yxp-modal .yxp-error.yxpInputGroup input,
.yxp-modal .yxp-error.yxpInputGroup select,
.yxp-modal .yxp-error.yxpInputGroup textarea,
.yxp-modal .yxp-error .yxpMultipleInputWrapper {
  border-color: #DE0000;
}
.yxpType_FORM .yxp-error-msg,
.yxp-rw-step .yxp-error-msg,
.yxp-modal .yxp-error-msg {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  text-transform: none;
  letter-spacing: 0;
  color: #DE0000;
  bottom: unset;
  top: -webkit-calc(100% - 30px);
  top: calc(100% - 30px);
  text-align: left;
  z-index: 1;
}
.yxpType_FORM .yxp-error-msg b, .yxpType_FORM .yxp-error-msg strong,
.yxp-rw-step .yxp-error-msg b,
.yxp-rw-step .yxp-error-msg strong,
.yxp-modal .yxp-error-msg b,
.yxp-modal .yxp-error-msg strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxpType_FORM .yxp-error-msg,
.comfortaa .yxp-rw-step .yxp-error-msg,
.comfortaa .yxp-modal .yxp-error-msg {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .yxpType_FORM .yxp-error-msg b, .comfortaa .yxpType_FORM .yxp-error-msg strong,
.comfortaa .yxp-rw-step .yxp-error-msg b,
.comfortaa .yxp-rw-step .yxp-error-msg strong,
.comfortaa .yxp-modal .yxp-error-msg b,
.comfortaa .yxp-modal .yxp-error-msg strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .yxpType_FORM .yxp-error-msg,
.omnes .yxp-rw-step .yxp-error-msg,
.omnes .yxp-modal .yxp-error-msg {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}
.yxpType_FORM .yxp-error-msg a,
.yxp-rw-step .yxp-error-msg a,
.yxp-modal .yxp-error-msg a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #DE0000;
  text-decoration: underline;
}
.yxpType_FORM .yxp-error-msg a b, .yxpType_FORM .yxp-error-msg a strong,
.yxp-rw-step .yxp-error-msg a b,
.yxp-rw-step .yxp-error-msg a strong,
.yxp-modal .yxp-error-msg a b,
.yxp-modal .yxp-error-msg a strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxpType_FORM .yxp-error-msg a,
.comfortaa .yxp-rw-step .yxp-error-msg a,
.comfortaa .yxp-modal .yxp-error-msg a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .yxpType_FORM .yxp-error-msg a b, .comfortaa .yxpType_FORM .yxp-error-msg a strong,
.comfortaa .yxp-rw-step .yxp-error-msg a b,
.comfortaa .yxp-rw-step .yxp-error-msg a strong,
.comfortaa .yxp-modal .yxp-error-msg a b,
.comfortaa .yxp-modal .yxp-error-msg a strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .yxpType_FORM .yxp-error-msg a,
.omnes .yxp-rw-step .yxp-error-msg a,
.omnes .yxp-modal .yxp-error-msg a {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}

.hamburger {
  height: 38px;
  width: 38px;
  background: white;
  padding: 0;
  margin-left: 28px;
  border: none;
}
.hamburger span {
  display: block;
  width: 100%;
  border-radius: 2px;
  height: 4px;
  background: #26337E;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}
.hamburger span + span {
  margin-top: 8px;
}
.hamburger.opened span:nth-child(1) {
  -webkit-animation: ease 0.4s top forwards;
          animation: ease 0.4s top forwards;
}
.hamburger.closed span:nth-child(1) {
  -webkit-animation: ease 0.4s top-2 forwards;
          animation: ease 0.4s top-2 forwards;
}
.hamburger.opened span:nth-child(2) {
  -webkit-animation: ease 0.4s scaled forwards;
          animation: ease 0.4s scaled forwards;
}
.hamburger.closed span:nth-child(2) {
  -webkit-animation: ease 0.4s scaled-2 forwards;
          animation: ease 0.4s scaled-2 forwards;
}
.hamburger.opened span:nth-child(3) {
  -webkit-animation: ease 0.4s bottom forwards;
          animation: ease 0.4s bottom forwards;
}
.hamburger.closed span:nth-child(3) {
  -webkit-animation: ease 0.4s bottom-2 forwards;
          animation: ease 0.4s bottom-2 forwards;
}

@-webkit-keyframes top {
  0% {
    top: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    top: 12px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    top: 12px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}

@keyframes top {
  0% {
    top: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    top: 12px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    top: 12px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}
@-webkit-keyframes top-2 {
  0% {
    top: 12px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  50% {
    top: 12px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    top: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes top-2 {
  0% {
    top: 12px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  50% {
    top: 12px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    top: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@-webkit-keyframes bottom {
  0% {
    bottom: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    bottom: 12px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    bottom: 12px;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
}
@keyframes bottom {
  0% {
    bottom: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    bottom: 12px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    bottom: 12px;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
}
@-webkit-keyframes bottom-2 {
  0% {
    bottom: 12px;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  50% {
    bottom: 12px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    bottom: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
@keyframes bottom-2 {
  0% {
    bottom: 12px;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  50% {
    bottom: 12px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    bottom: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
@-webkit-keyframes scaled {
  50% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes scaled {
  50% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes scaled-2 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scaled-2 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.personal-area-template {
  background: #009EE2;
  margin-top: 280px;
  position: relative;
}
.personal-area-template .yxpType_FORM {
  background: white;
  border: 1px solid rgba(38, 51, 126, 0.1);
  -webkit-box-shadow: 0 8px 40px rgba(39, 39, 39, 0.1);
          box-shadow: 0 8px 40px rgba(39, 39, 39, 0.1);
  border-radius: 8px;
  text-align: center;
  padding: 16px 24px;
  position: relative;
  width: -webkit-calc(100% - 20px);
  width: calc(100% - 20px);
  padding: 32px !important;
  margin: 16px auto 180px;
  max-width: 900px;
}
.personal-area-template > .yxpRow > .yxp-cols-12:not(.yxpType_CUSTOM) {
  padding: 20px;
}
.personal-area-template .yxpInputGroup:has(input[name=email][disabled]) {
  position: relative;
}
.personal-area-template .yxpInputGroup:has(input[name=email][disabled]) input {
  padding-right: 32px;
}
.personal-area-template .yxpInputGroup:has(input[name=email][disabled])::after {
  content: "\e90e";
  font-family: "sofidel" !important;
  font-size: 24px;
  color: #03499A;
  position: absolute;
  bottom: 45px;
  right: 0;
  line-height: 1;
}
.black-color .personal-area-template .yxpInputGroup:has(input[name=email][disabled])::after {
  color: #272727;
}
.orange-theme .personal-area-template .yxpInputGroup:has(input[name=email][disabled])::after {
  color: #F47920;
}
.personal-area-template .yxpFormActionsWrapper {
  position: absolute;
  bottom: -140px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.personal-area-template .yxp-save-message {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #83B86E;
  margin: 40px 0 0;
}
.personal-area-template .yxp-save-message b, .personal-area-template .yxp-save-message strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .personal-area-template .yxp-save-message {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .personal-area-template .yxp-save-message b, .comfortaa .personal-area-template .yxp-save-message strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .personal-area-template .yxp-save-message {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}

.generic-modal {
  display: none;
  position: fixed;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 16px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 110;
}
.generic-modal.open {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.generic-modal__overlay {
  position: absolute;
  background: rgba(39, 39, 39, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.generic-modal__card {
  position: relative;
  max-width: 840px;
  width: 100%;
  z-index: 1;
  max-height: 100vh;
  background: white;
  border-radius: 10px;
  padding: 56px 24px 32px;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  overflow: auto;
}
@media (min-width: 768px) {
  .generic-modal__card {
    padding: 56px 48px;
  }
}
.pop .generic-modal__card {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.generic-modal__close {
  cursor: pointer;
  position: absolute;
  color: #26337E;
  font-size: 32px;
  top: 20px;
  right: 20px;
}
.generic-modal__body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  gap: 12px;
}
.generic-modal__image {
  display: block;
  max-width: 100%;
  margin: 16px auto;
  width: 420px;
}
.generic-modal__title {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
}
.generic-modal__title b, .generic-modal__title strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .generic-modal__title {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa .generic-modal__title b, .comfortaa .generic-modal__title strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.generic-modal__title i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold-italic", sans-serif;
  font-size: 25px;
  line-height: 28px;
}
@media (min-width: 768px) {
  .generic-modal__title {
    font-size: 36px;
    line-height: 40px;
  }
  .generic-modal__title i {
    font-size: 38px;
    line-height: 40px;
  }
}
.generic-modal__disclaimer {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin: 32px 0 8px;
}
.generic-modal__disclaimer b, .generic-modal__disclaimer strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .generic-modal__disclaimer {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .generic-modal__disclaimer b, .comfortaa .generic-modal__disclaimer strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .generic-modal__disclaimer {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}

.header {
  background: white;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
}
.header.base {
  border-bottom: 1px solid rgba(57, 91, 110, 0.1);
  height: 80px;
}
@media (min-width: 1024px) {
  .header.base {
    height: 96px;
  }
}
.header.minimal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.header__minimal {
  height: 180px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.header__minimal img {
  height: 50px;
}
@media (min-width: 768px) {
  .header__minimal img {
    height: 100px;
  }
}
.header__wrapper {
  padding: 16px 20px;
  max-width: 1480px;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
}
.header__navigation, .header__right-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.header__navigation .secondary-button-yellow-small, .header__navigation .primary-button-yellow, .header__navigation .primary-button-small {
  display: none;
}
@media (min-width: 1024px) {
  .header__navigation .secondary-button-yellow-small, .header__navigation .primary-button-yellow, .header__navigation .primary-button-small {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: 20px;
  }
}
.header .header__logo {
  height: 50px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 16px;
  background: none;
}
.header__link-container {
  display: none;
}
@media (min-width: 1024px) {
  .header__link-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.header__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: none;
  position: relative;
  padding: 4px 0;
  color: #26337E;
  white-space: nowrap;
  margin: 16px 8px;
}
.header__link b, .header__link strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .header__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .header__link b, .comfortaa .header__link strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .header__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}
.header__link:hover {
  text-decoration: none;
}
.header__link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#26337E), to(#26337E));
  background-image: linear-gradient(#26337E, #26337E);
  -webkit-transition: background-size 0.2s;
  transition: background-size 0.2s;
  background-repeat: no-repeat;
  background-size: 0 2px;
  background-position: right bottom;
  border-radius: 2px;
}
.header__link:hover::before {
  background-size: 100% 2px;
  background-position: left bottom;
}
@media (min-width: 1280px) {
  .header__link {
    margin: 16px;
  }
}
.header__link[target=_BLANK] {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: none;
  position: relative;
  padding: 4px 0;
  color: #F47920;
  margin-right: 30px;
}
.header__link[target=_BLANK] b, .header__link[target=_BLANK] strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .header__link[target=_BLANK] {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .header__link[target=_BLANK] b, .comfortaa .header__link[target=_BLANK] strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .header__link[target=_BLANK] {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}
.header__link[target=_BLANK]:hover {
  text-decoration: none;
}
.header__link[target=_BLANK]::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#F47920), to(#F47920));
  background-image: linear-gradient(#F47920, #F47920);
  -webkit-transition: background-size 0.2s;
  transition: background-size 0.2s;
  background-repeat: no-repeat;
  background-size: 0 2px;
  background-position: right bottom;
  border-radius: 2px;
}
.header__link[target=_BLANK]:hover::before {
  background-size: 100% 2px;
  background-position: left bottom;
}
.header__link[target=_BLANK]::after {
  content: "\e907";
  font-family: "sofidel" !important;
  font-size: 24px;
  margin-left: 4px;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 100%;
  top: 50%;
}
.header__link--selected::before {
  background-size: 100% 2px;
  background-position: left bottom;
}
.header__login {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  background: white;
  color: #26337E;
  border: none;
  height: 48px;
  border: 1px solid #26337E;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 10px;
  width: 48px;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.comfortaa .header__login {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .header__login {
    padding: 0 30px;
    margin-left: 20px;
    width: auto;
  }
}
.header__login > span {
  font-size: 22px;
}
.header__login .label {
  display: none;
}
@media (min-width: 768px) {
  .header__login .label {
    padding-left: 8px;
    display: block;
  }
}
.header__login.user-logged {
  width: auto;
  padding: 0 30px;
}
.header__login.user-logged .label {
  padding-left: 8px;
  text-align: left;
  display: block;
  white-space: nowrap;
}
.primary-button-red .header__login.user-logged .label > * {
  color: white;
}
.primary-button-green .header__login.user-logged .label > * {
  color: white;
}
.header__login .surname {
  display: none;
}
@media (min-width: 1280px) {
  .header__login .surname {
    display: inline;
  }
}
.header__menu-button {
  display: block;
  position: relative;
}
@media (min-width: 1024px) {
  .header__menu-button {
    display: none;
  }
}
.header__menu-wrapper {
  position: fixed;
  background: linear-gradient(177.58deg, #FFF 13.08%, #01D6C3 214.14%);
  border-left: 1px solid rgba(57, 91, 110, 0.1);
  top: 80px;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  z-index: 20;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 430px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 50px;
}
@media (min-width: 1024px) {
  .header__menu-wrapper {
    display: none;
  }
}
.header__menu-wrapper.opened {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.header__menu-wrapper .header__link-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.text-small-omnes .header__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.footer {
  height: auto;
  background: white;
}
.footer__wrapper {
  padding: 42px 32px;
  max-width: 1780px;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.footer__social {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.footer__social-item {
  height: 40px;
  width: 40px;
  font-size: 20px;
  border-radius: 50%;
  color: white;
  background: #1C93DC;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: 8px;
}
.footer__social-item, .footer__social-item:hover {
  text-decoration: none;
}
.footer__disclaimer {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  margin: 16px 0;
}
.footer__disclaimer b, .footer__disclaimer strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .footer__disclaimer {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .footer__disclaimer b, .comfortaa .footer__disclaimer strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .footer__disclaimer {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}
.footer__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin: 8px 0;
}
.footer__link b, .footer__link strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .footer__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .footer__link b, .comfortaa .footer__link strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .footer__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
@media (min-width: 1024px) {
  .footer__link {
    padding: 0 8px;
  }
}
.footer__link-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 1024px) {
  .footer__link-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}
.footer__logo {
  height: 66px;
  margin: 8px 16px 8px 0;
}
.footer__logo img {
  margin-right: 15px;
}

.footer-fr {
  background: white;
  padding: 16px;
}
.footer-fr__wrapper {
  padding: 0 0 32px;
  max-width: 1300px;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 1024px) {
  .footer-fr__wrapper {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
}
.footer-fr__social {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 180px;
  gap: 8px;
}
@media (min-width: 1024px) {
  .footer-fr__social {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4;
  }
}
.footer-fr__social-item {
  height: 40px;
  width: 40px;
  font-size: 20px;
  border-radius: 50%;
  color: white;
  background: #1C93DC;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.footer-fr__social-item, .footer-fr__social-item:hover {
  text-decoration: none;
}
.footer-fr__disclaimer, .footer-fr__copyright {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
  padding: 12px 0;
}
.footer-fr__disclaimer b, .footer-fr__disclaimer strong, .footer-fr__copyright b, .footer-fr__copyright strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .footer-fr__disclaimer, .comfortaa .footer-fr__copyright {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .footer-fr__disclaimer b, .comfortaa .footer-fr__disclaimer strong, .comfortaa .footer-fr__copyright b, .comfortaa .footer-fr__copyright strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .footer-fr__disclaimer, .omnes .footer-fr__copyright {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}
@media (min-width: 1024px) {
  .footer-fr__disclaimer, .footer-fr__copyright {
    text-align: center;
  }
}
.footer-fr__copyright {
  border-top: 1px solid rgba(153, 153, 153, 0.3);
}
.footer-fr__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin: 8px 0;
}
.footer-fr__link b, .footer-fr__link strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .footer-fr__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .footer-fr__link b, .comfortaa .footer-fr__link strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .footer-fr__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
@media (min-width: 1024px) {
  .footer-fr__link {
    padding: 0 8px;
  }
}
.footer-fr__link-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}
@media (min-width: 1024px) {
  .footer-fr__link-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: auto;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
  }
}
.footer-fr__logo {
  height: 50px;
  width: 120px;
  margin: 8px 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.footer-fr__logo img {
  margin-right: 15px;
}
@media (min-width: 1024px) {
  .footer-fr__logo {
    width: 180px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
  }
}

.yxpImageTextWrapper {
  padding: 0 10px;
}
.yxpImageTextWrapper .yxpImageWrapper {
  width: 60%;
  max-width: 300px;
  margin: 0 auto 16px;
}
@media (min-width: 768px) {
  .image-medium .yxpImageTextWrapper .yxpImageWrapper {
    max-width: 300px;
    margin-bottom: 32px;
    width: 100%;
  }
}
.image-large .yxpImageTextWrapper .yxpImageWrapper {
  max-width: 470px;
}
.yxpImageTextWrapper .yxpImageTextTitle {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #F47920;
}
.yxpImageTextWrapper .yxpImageTextTitle b, .yxpImageTextWrapper .yxpImageTextTitle strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxpImageTextWrapper .yxpImageTextTitle {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa .yxpImageTextWrapper .yxpImageTextTitle b, .comfortaa .yxpImageTextWrapper .yxpImageTextTitle strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .yxpImageTextWrapper .yxpImageTextTitle {
    font-size: 21px;
    line-height: 28px;
  }
}
.image-large .yxpImageTextWrapper .yxpImageTextTitle {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
  color: #26337E;
}
.image-large .yxpImageTextWrapper .yxpImageTextTitle b, .image-large .yxpImageTextWrapper .yxpImageTextTitle strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .image-large .yxpImageTextWrapper .yxpImageTextTitle {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa .image-large .yxpImageTextWrapper .yxpImageTextTitle b, .comfortaa .image-large .yxpImageTextWrapper .yxpImageTextTitle strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.image-large .yxpImageTextWrapper .yxpImageTextTitle i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold-italic", sans-serif;
  font-size: 25px;
  line-height: 28px;
}
@media (min-width: 768px) {
  .image-large .yxpImageTextWrapper .yxpImageTextTitle {
    font-size: 36px;
    line-height: 40px;
  }
  .image-large .yxpImageTextWrapper .yxpImageTextTitle i {
    font-size: 38px;
    line-height: 40px;
  }
}
.image-large.nexa .yxpImageTextWrapper .yxpImageTextTitle {
  font-weight: normal;
  font-style: normal;
  font-family: "Nexa", sans-serif;
  color: #DA291C;
  font-size: 32px;
  line-height: 38px;
}
.image-large.nexa.light-green-title .yxpImageTextWrapper .yxpImageTextTitle {
  font-weight: normal;
  font-style: normal;
  font-family: "Nexa", sans-serif;
  color: #61AB30 !important;
  font-size: 32px;
  line-height: 38px;
}
.yxpImageTextWrapper .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
}
.yxpImageTextWrapper .yxpHtmlTextWrapper b, .yxpImageTextWrapper .yxpHtmlTextWrapper strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxpImageTextWrapper .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .yxpImageTextWrapper .yxpHtmlTextWrapper b, .comfortaa .yxpImageTextWrapper .yxpHtmlTextWrapper strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .yxpImageTextWrapper .yxpHtmlTextWrapper {
    font-size: 21px;
    line-height: 28px;
  }
}
.text-omnes .yxpImageTextWrapper .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
  font-size: 16px;
  line-height: 22px;
}
@media (min-width: 768px) {
  .text-omnes .yxpImageTextWrapper .yxpHtmlTextWrapper {
    font-size: 20px;
    line-height: 28px;
  }
}
.yxpImageTextWrapper.yxpFlexWrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 768px) {
  .yxpImageTextWrapper.yxpFlexWrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.yxpImageTextWrapper.yxpFlexWrapper .yxpInfoWrapper,
.yxpImageTextWrapper.yxpFlexWrapper .yxpImageWrapper {
  width: 100%;
}
@media (min-width: 768px) {
  .yxpImageTextWrapper.yxpFlexWrapper .yxpInfoWrapper,
  .yxpImageTextWrapper.yxpFlexWrapper .yxpImageWrapper {
    width: 50%;
  }
}
.yxpImageTextWrapper.yxpFlexWrapper .yxpImageWrapper {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
      -ms-flex-order: 2;
          order: 2;
}
@media (min-width: 768px) {
  .yxpImageTextWrapper.yxpFlexWrapper .yxpImageWrapper {
    -webkit-box-ordinal-group: unset;
    -webkit-order: unset;
        -ms-flex-order: unset;
            order: unset;
  }
}

.order-mobile ul {
  list-style: disc;
  list-style-position: inside;
}
@media (max-width: 767px) {
  .order-mobile .yxpImageTextWrapper.yxpFlexWrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
}
@media (max-width: 767px) {
  .order-mobile {
    text-align: center;
  }
}

@media (max-width: 767px) {
  body div.yxp-cols-3.image-medium,
  body div.yxp-cols-3.biggerImageWrapper {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .biggerImageWrapper .yxpImageTextWrapper .yxpImageWrapper {
    width: 100%;
    max-width: initial;
  }
}

.text-omnes .yxpImageTextWrapper .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}

.personal-area-header {
  margin-top: -190px;
  position: relative;
}
.personal-area-header__info {
  text-align: center;
  padding: 96px 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.personal-area-header__info h2 {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
}
.personal-area-header__user-name {
  margin-bottom: 16px;
}
.personal-area-header__logout {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  background: white;
  color: #26337E;
  border: none;
  height: 48px;
  border: 1px solid rgba(38, 51, 126, 0.2);
}
.comfortaa .personal-area-header__logout {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.personal-area-header__logout .icon-logout {
  margin-right: 8px;
}
.green-theme .personal-area-header__logout {
  background: white;
  border-color: #007039;
  border-radius: 8px !important;
  color: #007039;
}
.personal-area-header.red-version .personal-area-header__logout {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  background: white;
  color: #DA291C;
  border: none;
  height: 48px;
  border-radius: 8px !important;
  border: 2px solid #DA291C;
  padding: 0 24px;
}
.comfortaa .personal-area-header.red-version .personal-area-header__logout {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.personal-area-header__tabs {
  text-align: center;
  white-space: nowrap;
  overflow-x: auto;
  padding: 8px;
}
.personal-area-header__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding: 16px 24px;
  background: white;
  border: 1px solid rgba(38, 51, 126, 0.2);
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  -webkit-transform: scale(1);
          transform: scale(1);
  border-radius: 4px;
  margin-right: 8px;
  display: inline-block;
}
.personal-area-header__link b, .personal-area-header__link strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .personal-area-header__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .personal-area-header__link b, .comfortaa .personal-area-header__link strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .personal-area-header__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}
.personal-area-header__link:hover {
  text-decoration: none;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.personal-area-header__link.selected {
  background: #26337E;
  border-color: #26337E;
  color: white;
}
.orange-theme .personal-area-header__link.selected {
  background: #F47920;
  border-color: #F47920;
}
.green-theme .personal-area-header__link.selected {
  background: #007039;
  border-color: #007039;
}
.personal-area-header.red-version .personal-area-header__link {
  color: #272727;
}
.personal-area-header.red-version .personal-area-header__link.selected {
  background: #DA291C;
  border-color: #DA291C;
  color: white;
}

.yxpAccordionWrapper .yxpAccordionBlock {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  line-height: 20px;
  background: white;
  padding: 24px;
  margin-bottom: 24px;
  border: none;
  border-radius: 8px;
  position: relative;
}
.yxpAccordionWrapper .yxpAccordionBlock b, .yxpAccordionWrapper .yxpAccordionBlock strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxpAccordionWrapper .yxpAccordionBlock {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa .yxpAccordionWrapper .yxpAccordionBlock b, .comfortaa .yxpAccordionWrapper .yxpAccordionBlock strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .yxpAccordionWrapper .yxpAccordionBlock {
    font-size: 21px;
    line-height: 28px;
  }
}
.yxpAccordionWrapper .yxpAccordionBlock:before {
  content: "\e914";
  font-family: "sofidel" !important;
  font-size: 32px;
  position: absolute;
  right: 1%;
  top: 0;
  bottom: 0;
  -webkit-transform: translateY(30%);
          transform: translateY(30%);
  color: #F47920;
}
.yxpAccordionWrapper .yxpAccordionBlock.yxpAccordionActive {
  color: #F47920;
  background: white;
  border-radius: 8px 8px 0 0;
  margin-bottom: 0;
  border: none;
}
.yxpAccordionWrapper .yxpAccordionBlock.yxpAccordionActive:before {
  content: "\e915";
  font-family: "sofidel" !important;
  font-size: 32px;
}
.yxpAccordionWrapper .yxpAccordionContainer.yxpAccordionActive {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding: 8px 24px 32px;
  background: white;
  margin-bottom: 20px;
  border: none;
  border-radius: 0 0 8px 8px;
}
.yxpAccordionWrapper .yxpAccordionContainer.yxpAccordionActive b, .yxpAccordionWrapper .yxpAccordionContainer.yxpAccordionActive strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxpAccordionWrapper .yxpAccordionContainer.yxpAccordionActive {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .yxpAccordionWrapper .yxpAccordionContainer.yxpAccordionActive b, .comfortaa .yxpAccordionWrapper .yxpAccordionContainer.yxpAccordionActive strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .yxpAccordionWrapper .yxpAccordionContainer.yxpAccordionActive {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}

.accordion-orange {
  padding: 0 25px !important;
}
.accordion-orange .yxpAccordionWrapper .yxpAccordionBlock {
  background: #FEE5D6;
  margin-bottom: 4px;
}
.accordion-orange .yxpAccordionWrapper .yxpAccordionBlock:before {
  content: "\e901";
  font-family: "sofidel" !important;
  font-size: 24px;
  -webkit-transform: translateY(30%);
          transform: translateY(30%);
}
.accordion-orange .yxpAccordionWrapper .yxpAccordionBlock.yxpAccordionActive {
  border-radius: 8px;
}
.accordion-orange .yxpAccordionWrapper .yxpAccordionBlock.yxpAccordionActive:before {
  content: "\e901";
  font-family: "sofidel" !important;
  font-size: 24px;
  -webkit-transform: translateY(-30%) rotate(180deg);
          transform: translateY(-30%) rotate(180deg);
}
.accordion-orange .yxpAccordionWrapper .yxpAccordionContainer.yxpAccordionActive {
  background: #FEE5D6;
  padding: 16px 32px;
  border-radius: 8px;
}
.accordion-orange .yxpAccordionWrapper .yxpAccordionContainer.yxpAccordionActive .yxpHtmlTextWrapper {
  margin-bottom: 0;
}
.accordion-orange .yxpAccordionWrapper .yxpAccordionContainer.yxpAccordionActive ul {
  list-style: disc;
}

.my-activities {
  position: relative;
  max-width: 900px;
  margin: 0 auto 90px;
}
.my-activities__wrapper {
  background: white;
  border: 1px solid rgba(38, 51, 126, 0.1);
  -webkit-box-shadow: 0 8px 40px rgba(39, 39, 39, 0.1);
          box-shadow: 0 8px 40px rgba(39, 39, 39, 0.1);
  border-radius: 8px;
  text-align: center;
  padding: 16px 24px;
  padding: 16px;
}
@media (min-width: 768px) {
  .my-activities__wrapper {
    padding: 32px;
  }
}
.my-activities__separator {
  border-bottom: 1px solid rgba(38, 51, 126, 0.2);
  margin: 32px 0;
}
.my-activities__item {
  text-align: left;
  border-radius: 8px;
}
.my-activities__item.product {
  background: rgba(28, 147, 220, 0.1);
  padding: 12px;
}
.my-activities__item.prize {
  background: rgba(38, 51, 126, 0.1);
  padding: 12px;
}
.my-activities__info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.my-activities__label {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin-right: 16px;
}
.my-activities__label b, .my-activities__label strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .my-activities__label {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .my-activities__label b, .comfortaa .my-activities__label strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.my-activities__label i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
@media (min-width: 768px) {
  .my-activities__label {
    font-size: 20px;
    line-height: 24px;
  }
}
.my-activities__date {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  white-space: nowrap;
}
.my-activities__date b, .my-activities__date strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .my-activities__date {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .my-activities__date b, .comfortaa .my-activities__date strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .my-activities__date {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
.my-activities__point {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
  line-height: 1;
  color: #26337E;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 28px;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  font-size: 20px;
  margin-left: 16px;
  margin-right: -8px;
}
.my-activities__point i {
  padding-left: 6px;
}
.my-activities__point::before {
  content: "";
  margin: 0 8px;
  width: 40px;
  height: 40px;
  background: url("/fe-web/img/sofidel/icons/point.png") no-repeat center;
  background-size: contain;
}
.my-activities__point span {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.my-activities__point i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-semibold-italic", sans-serif;
  font-size: 16px;
  color: #26337E;
}
.my-activities__point::before {
  width: 24px;
  height: 24px;
}
.my-activities__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  line-height: 20px;
  background: rgba(244, 121, 32, 0.1);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 8px;
  margin-top: 32px;
  padding: 16px;
}
.my-activities__link b, .my-activities__link strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .my-activities__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa .my-activities__link b, .comfortaa .my-activities__link strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .my-activities__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
.my-activities__link:after {
  content: "\e913";
  font-family: "sofidel" !important;
  font-size: 24px;
  color: #F47920;
}
.my-activities__link:hover {
  text-decoration: none;
}
.my-activities__link.blue-link {
  background: rgba(255, 255, 255, 0.6);
}
.my-activities__link.blue-link:after {
  color: #1C93DC;
}
.my-activities__status {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 12px;
  line-height: 16px;
  vertical-align: middle;
  background: white;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 4px 6px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.my-activities__status b, .my-activities__status strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .my-activities__status {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .my-activities__status b, .comfortaa .my-activities__status strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .my-activities__status {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}
@media (min-width: 768px) {
  .my-activities__status {
    margin-bottom: 0;
  }
}
.my-activities__status.APPROVED, .my-activities__status.SENT {
  color: #83B86E;
}
.my-activities__status.APPROVED:after, .my-activities__status.SENT:after {
  content: "\e904";
  font-family: "sofidel" !important;
  font-size: 10px;
  margin-left: 6px;
}
.my-activities__status.FAILED, .my-activities__status.REJECTED {
  color: #DE0000;
}
.my-activities__status.FAILED:after, .my-activities__status.REJECTED:after {
  content: "\e905";
  font-family: "sofidel" !important;
  font-size: 16px;
  margin-left: 6px;
}
.my-activities__status.REQUESTED {
  color: #1C93DC;
}
.my-activities__status.REQUESTED:after {
  content: "\e912";
  font-family: "sofidel" !important;
  font-size: 16px;
  margin-left: 6px;
}
.my-activities__status.DRAFT {
  color: #999999;
}
.my-activities__status.DRAFT:after {
  content: "\e904";
  font-family: "sofidel" !important;
  font-size: 10px;
  margin-left: 6px;
}
.my-activities__right-wrapper {
  text-align: right;
}
.my-activities__product {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 14px;
  line-height: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 16px;
}
.my-activities__product b, .my-activities__product strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .my-activities__product {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa .my-activities__product b, .comfortaa .my-activities__product strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .my-activities__product {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
.my-activities__img {
  margin-right: 16px;
  background: white;
  border-radius: 4px;
  width: 80px;
  height: 80px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.my-activities__no-results {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 32px 0;
}
.my-activities__no-results b, .my-activities__no-results strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .my-activities__no-results {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .my-activities__no-results b, .comfortaa .my-activities__no-results strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .my-activities__no-results {
    font-size: 21px;
    line-height: 28px;
  }
}
.my-activities__no-results.hidden {
  display: none;
}
.my-activities__no-results a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #F47920;
  font-size: 16px;
  color: white;
  border: none;
  height: 48px;
  margin-top: 32px;
}
.comfortaa .my-activities__no-results a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}

.contest-expiration {
  min-height: -webkit-calc(100vh - 95px - 219px);
  min-height: calc(100vh - 95px - 219px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.product-swiper {
  position: relative;
}
.product-swiper .swiper {
  margin: 0 -20px;
}
@media (min-width: 768px) {
  .product-swiper .swiper {
    margin: 0 40px;
  }
}
.product-swiper .swiper-button-next,
.product-swiper .swiper-button-prev {
  top: 34%;
}

.swiper-button-prev {
  color: #1C93DC;
  background-image: none;
  left: 0;
}
.swiper-button-prev:after {
  content: "\e902";
  font-family: "sofidel" !important;
  font-size: 32px;
}
@media (max-width: 767px) {
  .swiper-button-prev {
    display: none;
  }
}

.swiper-button-next {
  color: #1C93DC;
  background-image: none;
  right: 0;
}
.swiper-button-next:after {
  content: "\e903";
  font-family: "sofidel" !important;
  font-size: 32px;
}
@media (max-width: 767px) {
  .swiper-button-next {
    display: none;
  }
}

.visual-component {
  position: relative;
  min-height: 240vw;
}
@media (min-width: 768px) {
  .visual-component {
    min-height: 66vw;
    max-height: 96vh;
  }
}
@media (min-width: 1024px) {
  .visual-component {
    min-height: 52vw;
  }
}
.visual-component__wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 94vw 10px 0;
  text-align: center;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .visual-component__wrapper {
    padding-top: 3vw;
  }
}
.visual-component__background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.visual-component__info {
  max-width: 100%;
  margin: 0 auto;
  color: white;
  padding: 0 0 46px;
  min-height: 420px;
  width: 400px;
}
@media (min-width: 768px) {
  .visual-component__info {
    margin: 0;
  }
}
@media (min-width: 1024px) {
  .visual-component__info {
    padding: 0 54px 46px;
  }
}
.visual-component__logo {
  width: 320px;
  max-width: 60vw;
}
@media (min-width: 768px) {
  .visual-component__logo {
    max-width: 28vw;
  }
}
@media (min-width: 1024px) {
  .visual-component__logo {
    max-width: 35vh;
  }
}
.visual-component__description {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin: 24px 0;
}
.visual-component__description b, .visual-component__description strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .visual-component__description {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .visual-component__description b, .comfortaa .visual-component__description strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .visual-component__description {
    font-size: 21px;
    line-height: 28px;
  }
}
.visual-component__description em {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold-italic", sans-serif;
  color: #26337E;
}
.visual-component__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #F47920;
  font-size: 16px;
  color: white;
  border: none;
  height: 55px;
}
.comfortaa .visual-component__link {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.visual-component__product {
  position: absolute;
  z-index: 2;
  bottom: 30px;
  width: 520px;
  max-width: 80%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
}
@media (min-width: 768px) {
  .visual-component__product {
    max-width: 40%;
  }
}
.visual-component__points {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
  line-height: 1;
  color: #F47920;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 28px;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  margin-top: 16px;
  background: rgba(255, 255, 255, 0.6);
  padding: 8px 0 8px 8px;
  border-radius: 8px;
}
.visual-component__points i {
  padding-left: 6px;
}
.visual-component__points::before {
  content: "";
  margin: 0 8px;
  width: 40px;
  height: 40px;
  background: url("/fe-web/img/sofidel/icons/point.png") no-repeat center;
  background-size: contain;
}
.visual-component__points span {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.visual-component__points i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-semibold-italic", sans-serif;
  font-size: 16px;
  color: #26337E;
}
.visual-component__points::before {
  width: 36px;
  height: 36px;
}
.visual-component__disclaimer {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
}
.visual-component__disclaimer b, .visual-component__disclaimer strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .visual-component__disclaimer {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .visual-component__disclaimer b, .comfortaa .visual-component__disclaimer strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .visual-component__disclaimer {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
.visual-component__button-wrapper a {
  margin: 32px 0;
}

.yxp-coupon-row {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.yxp-coupon-row.full {
  position: relative;
  background-color: white;
  --mask: radial-gradient(40px at 40px 40px,#0000 98%,#000) -40px -40px;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  padding: 48px;
}
@media (max-width: 767px) {
  .yxp-coupon-row.full {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.yxp-coupon-row.full .yxp-coupon-title {
  text-align: center;
}
.yxp-coupon-row.full .yxp-coupon-description {
  visibility: hidden;
}
.yxp-coupon-row.full .yxp-coupon-disclaimer {
  display: none;
}

.yxp-coupon-cta-wrapper {
  text-align: center;
}
.yxp-coupon-cta-wrapper a {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #03499A;
  font-size: 16px;
  color: white;
  border: none;
  height: 48px;
}
.comfortaa .yxp-coupon-cta-wrapper a {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.yxp-coupon-cta-wrapper .yxp-coupon-coupon-availability {
  display: none;
}

.yxp-lt-couponlist-container .yxp-lt-couponlist-wrapper {
  min-height: 0;
}
.yxp-lt-couponlist-container .loader-wrapper {
  background: none;
}
.yxp-lt-couponlist-container .loader-wrapper::after {
  content: "";
  border: 4px solid transparent;
  border-top-color: #F47920;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  position: absolute;
  z-index: 10;
  top: -webkit-calc(50% - 20px);
  top: calc(50% - 20px);
  left: -webkit-calc(50% - 20px);
  left: calc(50% - 20px);
}
.black-color .yxp-lt-couponlist-container .loader-wrapper::after {
  border-top-color: #007039;
}
.yxp-lt-couponlist-container .loader-wrapper::before {
  content: " ";
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
}
.yxp-lt-couponlist-container .loader-wrapper .loader, .yxp-lt-couponlist-container .loader-wrapper:before {
  display: none !important;
}
.yxp-lt-couponlist-container .yxp-modal-title {
  margin-bottom: 24px;
}
.yxp-lt-couponlist-container .yxp-modal-description {
  word-break: break-word;
  font-size: 18px;
}
.yxp-lt-couponlist-container .yxp-coupon-print.disabled {
  opacity: 0.6;
  cursor: default;
}
.yxp-lt-couponlist-container .yxp-lt-couponlist-wrapper .yxp-lt-couponlist-results .yxp-coupon-row {
  margin: 50px 10px;
}

.digital-rewards__description {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  margin-bottom: 24px;
}
.digital-rewards__countdown {
  margin: 8px 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0 8px;
  background: #D2F9F5;
  border-radius: 12px;
}
.digital-rewards__prizes {
  padding: 60px 32px 80px;
  margin: 0 -20px 56px;
  max-width: 900px;
}
@media (min-width: 768px) {
  .digital-rewards__prizes {
    margin: 0 auto 56px;
  }
}
.digital-rewards__prize-label {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
}
.digital-rewards__prize-label b, .digital-rewards__prize-label strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .digital-rewards__prize-label {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .digital-rewards__prize-label b, .comfortaa .digital-rewards__prize-label strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .digital-rewards__prize-label {
    font-size: 21px;
    line-height: 28px;
  }
}
.digital-rewards__countdown-box {
  padding: 8px;
  font-size: 10px;
  line-height: 1.2;
}
.digital-rewards__countdown-box strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
  font-size: 30px;
  line-height: 1;
}
.digital-rewards__two-dots {
  padding: 12px 0 0;
  font-size: 16px;
}
.digital-rewards__button {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #F47920;
  font-size: 16px;
  color: white;
  border: none;
  height: 65px;
}
.comfortaa .digital-rewards__button {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .digital-rewards__button {
    font-size: 21px;
  }
}
.digital-rewards__points {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
  line-height: 1;
  color: #F47920;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 28px;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  font-size: 20px;
  background: rgba(244, 121, 32, 0.1);
  border-radius: 8px;
  padding: 8px 0 8px 8px;
}
.digital-rewards__points i {
  padding-left: 6px;
}
.digital-rewards__points::before {
  content: "";
  margin: 0 8px;
  width: 40px;
  height: 40px;
  background: url("/fe-web/img/sofidel/icons/point.png") no-repeat center;
  background-size: contain;
}
.digital-rewards__points span {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.digital-rewards__points i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-semibold-italic", sans-serif;
  font-size: 16px;
  color: #26337E;
}
.digital-rewards__points::before {
  width: 24px;
  height: 24px;
}
.digital-rewards__image {
  margin-bottom: -50px;
}
.digital-rewards__card {
  position: relative;
  z-index: 2;
  max-width: 280px;
  margin: 0 auto;
}
.digital-rewards__card h5 {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
}
.digital-rewards__card h5 b, .digital-rewards__card h5 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .digital-rewards__card h5 {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .digital-rewards__card h5 b, .comfortaa .digital-rewards__card h5 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.digital-rewards__card h5 i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
@media (min-width: 768px) {
  .digital-rewards__card h5 {
    font-size: 20px;
    line-height: 24px;
  }
}
.digital-rewards__card .caption {
  line-height: 1;
  margin: 12px 0;
  color: black;
}
@media (min-width: 768px) {
  .digital-rewards .swiper-wrapper {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.digital-rewards .swiper-slide {
  background: url("/fe-web/img/sofidel/backgrounds/circle-gradient.png") no-repeat center;
  background-size: 90% auto;
}
@media (min-width: 768px) {
  .digital-rewards .swiper-slide {
    background-size: contain;
  }
}
.digital-rewards .swiper-button-next,
.digital-rewards .swiper-button-prev {
  color: #F47920;
}
.digital-rewards .swiper-button-next.swiper-button-disabled,
.digital-rewards .swiper-button-prev.swiper-button-disabled {
  display: none;
}
.digital-rewards.next-month .digital-rewards__prize-label, .digital-rewards.next-month .digital-rewards__next-month {
  display: none;
}
.digital-rewards.next-month .swiper-slide {
  background-image: url("/fe-web/img/sofidel/backgrounds/circle-gradient-white.png");
}
.digital-rewards.next-month .digital-rewards__date {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
}
.digital-rewards.next-month .digital-rewards__date b, .digital-rewards.next-month .digital-rewards__date strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .digital-rewards.next-month .digital-rewards__date {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .digital-rewards.next-month .digital-rewards__date b, .comfortaa .digital-rewards.next-month .digital-rewards__date strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .digital-rewards.next-month .digital-rewards__date {
    font-size: 21px;
    line-height: 28px;
  }
}

.prize-confirmed-page .yxpType_LINK {
  text-align: left;
  border-radius: 8px;
  padding: 16px !important;
  border: 1px solid rgba(38, 51, 126, 0.2);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background: white;
  margin-bottom: 24px;
}
.prize-confirmed-page .yxpType_LINK a,
.prize-confirmed-page .yxpType_LINK .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #26337E;
  text-decoration: none;
  cursor: default;
  margin: 0;
}
.prize-confirmed-page .yxpType_LINK a b, .prize-confirmed-page .yxpType_LINK a strong,
.prize-confirmed-page .yxpType_LINK .yxpHtmlTextWrapper b,
.prize-confirmed-page .yxpType_LINK .yxpHtmlTextWrapper strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .prize-confirmed-page .yxpType_LINK a,
.comfortaa .prize-confirmed-page .yxpType_LINK .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .prize-confirmed-page .yxpType_LINK a b, .comfortaa .prize-confirmed-page .yxpType_LINK a strong,
.comfortaa .prize-confirmed-page .yxpType_LINK .yxpHtmlTextWrapper b,
.comfortaa .prize-confirmed-page .yxpType_LINK .yxpHtmlTextWrapper strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .prize-confirmed-page .yxpType_LINK a,
.omnes .prize-confirmed-page .yxpType_LINK .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
.prize-confirmed-page .yxpType_LINK:before {
  content: "\e90d";
  font-family: "sofidel" !important;
  font-size: 40px;
  color: #F47920;
  margin-right: 16px;
}

.prize-confirmed {
  max-width: 400px;
  margin: -50px auto 70px;
  background: url("/fe-web/img/sofidel/backgrounds/circle-gradient-white.png") no-repeat center;
  background-size: 80% auto;
}
@media (min-width: 768px) {
  .prize-confirmed {
    background-size: contain;
  }
}
.prize-confirmed h5 {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
}
.prize-confirmed h5 b, .prize-confirmed h5 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .prize-confirmed h5 {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .prize-confirmed h5 b, .comfortaa .prize-confirmed h5 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.prize-confirmed h5 i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
@media (min-width: 768px) {
  .prize-confirmed h5 {
    font-size: 20px;
    line-height: 24px;
  }
}
.prize-confirmed .caption {
  line-height: 1;
  margin: 12px 0;
  color: black;
}
.prize-confirmed__points {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
  line-height: 1;
  color: #F47920;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 28px;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  font-size: 20px;
  background: rgba(244, 121, 32, 0.1);
  border-radius: 8px;
  padding: 8px 0 8px 8px;
}
.prize-confirmed__points i {
  padding-left: 6px;
}
.prize-confirmed__points::before {
  content: "";
  margin: 0 8px;
  width: 40px;
  height: 40px;
  background: url("/fe-web/img/sofidel/icons/point.png") no-repeat center;
  background-size: contain;
}
.prize-confirmed__points span {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.prize-confirmed__points i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-semibold-italic", sans-serif;
  font-size: 16px;
  color: #26337E;
}
.prize-confirmed__points::before {
  width: 24px;
  height: 24px;
}
.prize-confirmed__card {
  position: relative;
  max-width: 86%;
  margin: -50px auto 0;
}

.prizes-request__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 33px;
}
@media (max-width: 767px) {
  .prizes-request__wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.prizes-request__points {
  background: rgba(244, 121, 32, 0.1);
  border-radius: 8px;
  padding: 8px 0 8px 8px;
  margin: 0;
}
.prizes-request__generic-card {
  background: white;
  border: 1px solid rgba(38, 51, 126, 0.1);
  -webkit-box-shadow: 0 8px 40px rgba(39, 39, 39, 0.1);
          box-shadow: 0 8px 40px rgba(39, 39, 39, 0.1);
  border-radius: 8px;
  text-align: center;
  padding: 16px 24px;
  padding: 25px 28px;
  position: relative;
  max-width: 340px;
}
.prizes-request__generic-card input[type=radio] {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
}
.prizes-request__generic-card .checkmark {
  position: absolute;
  top: 24px;
  right: 24px;
  border-radius: 50%;
  border: 2px solid rgba(38, 51, 126, 0.5);
  width: 32px;
  height: 32px;
}
.prizes-request__generic-card input:checked ~ .checkmark {
  border: none;
}
.prizes-request__generic-card .checkmark:after {
  content: "";
  display: inline-block;
  width: 36px;
  height: 32px;
  margin: -1px;
  background: url("/fe-web/img/sofidel/icons/prizesCheck.svg") no-repeat center;
  background-size: contain;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.prizes-request__generic-card input:checked ~ .checkmark:after {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.prizes-request__generic-card p {
  font-size: 14px;
  line-height: 16px;
  margin: 13px 0;
}
.prizes-request__generic-card img {
  width: 90%;
  margin: 32px auto 0;
}
.prizes-request .border-card {
  border: 1px solid #F47920;
}
.prizes-request__button {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #F47920;
  font-size: 16px;
  color: white;
  border: none;
  height: 55px;
  margin-top: 48px;
}
.comfortaa .prizes-request__button {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.prizes-request__no-results {
  padding: 50px 0;
}

.yxp-reward-list-widget {
  margin: 0 -20px;
  padding: 0 20px;
}
.yxp-reward-list-widget .yxp-reward-item-wrapper {
  background: url("/fe-web/img/sofidel/backgrounds/circle-gradient-white.png") no-repeat top center;
  background-size: contain;
}
.yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-card {
  max-width: 290px;
}
.yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-name {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 20px;
  line-height: 28px;
}
.yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-name b, .yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-name strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-name {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa .yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-name b, .comfortaa .yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-name strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-name {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
@media (min-width: 768px) {
  .yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-name {
    font-size: 28px;
    line-height: 36px;
  }
}
.yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-image {
  width: 200px;
  height: 210px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (min-width: 768px) {
  .yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-image {
    margin-bottom: -32px;
    margin-top: 16px;
    width: 80%;
  }
}
.yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-description {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #272727;
}
.yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-description b, .yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-description strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-description {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-description b, .comfortaa .yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-description strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .yxp-reward-list-widget .yxp-reward-item-wrapper .yxp-reward-item-description {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}

.load-receipt-invoice {
  padding-top: 0 !important;
  max-width: 850px;
  margin: 0 auto;
}
.load-receipt-invoice .loader-wrapper .loader {
  border: 6px solid transparent;
  border-top-color: #F47920;
}
.load-receipt-invoice .yxpH5 {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #999999;
  margin-bottom: 56px;
}
.load-receipt-invoice .yxpH5 b, .load-receipt-invoice .yxpH5 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .load-receipt-invoice .yxpH5 {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .load-receipt-invoice .yxpH5 b, .comfortaa .load-receipt-invoice .yxpH5 strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.load-receipt-invoice .yxpH5 i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
@media (min-width: 768px) {
  .load-receipt-invoice .yxpH5 {
    font-size: 20px;
    line-height: 24px;
  }
}
.load-receipt-invoice .first-step img {
  max-width: 120px;
}
.load-receipt-invoice .yxp-rw-step .title {
  display: none;
}
.load-receipt-invoice .yxp-rw-step .subtitle {
  color: #03499A;
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  margin-top: 56px;
}
.black-color .load-receipt-invoice .yxp-rw-step .subtitle {
  color: #272727;
}
.orange-theme .load-receipt-invoice .yxp-rw-step .subtitle {
  color: #F47920;
}
.load-receipt-invoice .yxp-rw-step .subtitle b, .load-receipt-invoice .yxp-rw-step .subtitle strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-step .subtitle {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-step .subtitle b, .comfortaa .load-receipt-invoice .yxp-rw-step .subtitle strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .load-receipt-invoice .yxp-rw-step .subtitle {
    font-size: 21px;
    line-height: 28px;
  }
}
.load-receipt-invoice a.cta {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #F47920;
  font-size: 16px;
  color: white;
  border: none;
  height: 65px;
  margin-top: 48px;
}
.comfortaa .load-receipt-invoice a.cta {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .load-receipt-invoice a.cta {
    font-size: 21px;
  }
}
.load-receipt-invoice .yxpType_UPLOAD_INPUT label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  min-height: 72px;
  border-bottom: 2px solid #26337E;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  gap: 8px;
}
@media (min-width: 768px) {
  .load-receipt-invoice .yxpType_UPLOAD_INPUT label {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
}
.load-receipt-invoice .yxpInputGroup--error label {
  border-color: #DE0000;
}
.load-receipt-invoice .currency-symbol {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #999999;
}
.load-receipt-invoice .currency-symbol b, .load-receipt-invoice .currency-symbol strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .load-receipt-invoice .currency-symbol {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .load-receipt-invoice .currency-symbol b, .comfortaa .load-receipt-invoice .currency-symbol strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .load-receipt-invoice .currency-symbol {
    font-size: 21px;
    line-height: 28px;
  }
}
.load-receipt-invoice .yxp-rw2-steps-wrapper .file-button {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 14px;
  line-height: 20px;
  background: #26337E;
  padding: 12px 16px;
  white-space: nowrap;
  border-radius: 30px;
  position: static;
  border: none;
  margin: 4px 0;
  color: white;
}
.load-receipt-invoice .yxp-rw2-steps-wrapper .file-button b, .load-receipt-invoice .yxp-rw2-steps-wrapper .file-button strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw2-steps-wrapper .file-button {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw2-steps-wrapper .file-button b, .comfortaa .load-receipt-invoice .yxp-rw2-steps-wrapper .file-button strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .load-receipt-invoice .yxp-rw2-steps-wrapper .file-button {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
.load-receipt-invoice .yxp-rw2-steps-wrapper .file-box {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  color: #999999;
  border: none;
  width: auto;
  height: auto;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin-right: 16px;
}
.load-receipt-invoice .yxp-rw2-steps-wrapper .file-box b, .load-receipt-invoice .yxp-rw2-steps-wrapper .file-box strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw2-steps-wrapper .file-box {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw2-steps-wrapper .file-box b, .comfortaa .load-receipt-invoice .yxp-rw2-steps-wrapper .file-box strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.load-receipt-invoice .yxp-rw2-steps-wrapper .file-box i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
@media (min-width: 768px) {
  .load-receipt-invoice .yxp-rw2-steps-wrapper .file-box {
    font-size: 20px;
    line-height: 24px;
  }
}
.load-receipt-invoice .yxp-rw2-steps-wrapper .yxpInputGroup {
  margin-top: 24px;
  text-align: left;
}
.load-receipt-invoice .yxp-rw2-steps-wrapper .yxpInputGroup--back {
  display: none;
}
.load-receipt-invoice .yxp-rw2-steps-wrapper .yxp-cols-6 {
  width: 100%;
  text-align: left;
}
.load-receipt-invoice .yxp-rw2-steps-wrapper .yxp-cols-6.empty {
  display: none;
}
.load-receipt-invoice .yxp-rw2-steps-wrapper .yxp-rw-step.active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.load-receipt-invoice .yxp-rw2-steps-wrapper .yxp-rw-step.active.complete .yxpRow:has(.yxpInfoLink) {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
}
.load-receipt-invoice .yxp-rw2-steps-wrapper .yxp-rw-step.active.complete .yxpRow:nth-child(6) {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
      -ms-flex-order: 2;
          order: 2;
}
.load-receipt-invoice .yxp-rw2-steps-wrapper .yxp-rw-step.hidden {
  display: none;
}
.load-receipt-invoice .yxpInputGroup--success .file-box {
  color: #26337E;
  border: 1px solid rgba(38, 51, 126, 0.2);
  padding: 8px 10px;
}
.load-receipt-invoice .yxpListSelectionWrapper {
  text-align: left;
  margin-top: 48px;
}
.load-receipt-invoice .yxpListSelectionWrapper label {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #03499A;
}
.load-receipt-invoice .yxpListSelectionWrapper label b, .load-receipt-invoice .yxpListSelectionWrapper label strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .load-receipt-invoice .yxpListSelectionWrapper label {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .load-receipt-invoice .yxpListSelectionWrapper label b, .comfortaa .load-receipt-invoice .yxpListSelectionWrapper label strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .load-receipt-invoice .yxpListSelectionWrapper label {
    font-size: 21px;
    line-height: 28px;
  }
}
.black-color .load-receipt-invoice .yxpListSelectionWrapper label {
  color: #272727;
}
.orange-theme .load-receipt-invoice .yxpListSelectionWrapper label {
  color: #F47920;
}
.load-receipt-invoice .yxpInfoLink {
  text-align: left;
  border-radius: 8px;
  padding: 16px !important;
  border: 1px solid rgba(38, 51, 126, 0.2);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background: white;
  margin: 56px 12px 0;
  width: -webkit-calc(100% - 24px);
  width: calc(100% - 24px);
}
.load-receipt-invoice .yxpInfoLink a,
.load-receipt-invoice .yxpInfoLink .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #26337E;
  text-decoration: none;
  cursor: default;
  margin: 0;
}
.load-receipt-invoice .yxpInfoLink a b, .load-receipt-invoice .yxpInfoLink a strong,
.load-receipt-invoice .yxpInfoLink .yxpHtmlTextWrapper b,
.load-receipt-invoice .yxpInfoLink .yxpHtmlTextWrapper strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .load-receipt-invoice .yxpInfoLink a,
.comfortaa .load-receipt-invoice .yxpInfoLink .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .load-receipt-invoice .yxpInfoLink a b, .comfortaa .load-receipt-invoice .yxpInfoLink a strong,
.comfortaa .load-receipt-invoice .yxpInfoLink .yxpHtmlTextWrapper b,
.comfortaa .load-receipt-invoice .yxpInfoLink .yxpHtmlTextWrapper strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .load-receipt-invoice .yxpInfoLink a,
.omnes .load-receipt-invoice .yxpInfoLink .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
.load-receipt-invoice .yxpInfoLink:before {
  content: "\e900";
  font-family: "sofidel" !important;
  font-size: 40px;
  color: #F47920;
  margin-right: 16px;
}
.load-receipt-invoice .yxp-rw-product-wrapper {
  margin: 32px -4px;
  -webkit-box-align: normal;
  -webkit-align-items: normal;
      -ms-flex-align: normal;
          align-items: normal;
  width: auto;
}
.load-receipt-invoice .yxpType_UPLOAD_INPUT .yxpInputGroup {
  margin-top: 0;
  padding-top: 0;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product {
  width: -webkit-calc(50% - 8px);
  width: calc(50% - 8px);
  margin: 0 4px 8px;
  border-radius: 16px;
  padding: 24px 16px;
  border: 1px solid rgba(38, 51, 126, 0.2);
  min-height: 290px;
}
@media (min-width: 768px) {
  .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product {
    width: -webkit-calc(33.33% - 8px);
    width: calc(33.33% - 8px);
  }
}
@media (min-width: 1024px) {
  .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product {
    width: -webkit-calc(25% - 8px);
    width: calc(25% - 8px);
  }
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product.yxp-rw-add-product-button {
  background: rgba(38, 51, 126, 0.05);
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-icon {
  color: #03499A;
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid rgba(38, 51, 126, 0.2);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  margin-bottom: 10px;
  padding: 6px 0;
}
.black-color .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-icon {
  color: #272727;
}
.orange-theme .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-icon {
  color: #F47920;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-icon b, .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-icon strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-icon {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-icon b, .comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-icon strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-icon {
    font-size: 21px;
    line-height: 28px;
  }
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-label {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #26337E;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-label b, .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-label strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-label {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-label b, .comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-label strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__add-label {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__image {
  height: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 10px;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__product-name {
  line-height: 1;
  text-align: center;
  min-height: 50px;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__product-name > label {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding: 0 !important;
  color: #26337E;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__product-name > label b, .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__product-name > label strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__product-name > label {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__product-name > label b, .comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__product-name > label strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__product-name > label {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__product-second-name {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 14px;
  line-height: 20px;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__product-second-name b, .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__product-second-name strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__product-second-name {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__product-second-name b, .comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__product-second-name strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__product-second-name {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper {
  margin-top: 24px;
  width: 100%;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .value {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #26337E;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .value b, .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .value strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .value {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .value b, .comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .value strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .value {
    font-size: 21px;
    line-height: 28px;
  }
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .modifier {
  color: #03499A;
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid rgba(38, 51, 126, 0.2);
  width: 32px;
  height: 32px;
  text-decoration: none !important;
}
.black-color .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .modifier {
  color: #272727;
}
.orange-theme .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .modifier {
  color: #F47920;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .modifier b, .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .modifier strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .modifier {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .modifier b, .comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .modifier strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .modifier i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
@media (min-width: 768px) {
  .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product__modifier-wrapper .modifier {
    font-size: 20px;
    line-height: 24px;
  }
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount {
  margin-top: 12px;
  padding: 0;
  width: 100%;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount .yxpMultipleInputWrapper {
  border: 1px solid rgba(38, 51, 126, 0.2);
  border-radius: 16px;
  overflow: hidden;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount label {
  display: none;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount input {
  display: inline;
  text-align: left;
  width: 30px;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount input:first-child {
  text-align: right;
  width: 48px;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount input,
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount .separator:after {
  color: #03499A;
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
}
.black-color .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount input,
.black-color .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount .separator:after {
  color: #272727;
}
.orange-theme .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount input,
.orange-theme .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount .separator:after {
  color: #F47920;
}
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount input b, .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount input strong,
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount .separator:after b,
.load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount .separator:after strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount input,
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount .separator:after {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount input b, .comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount input strong,
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount .separator:after b,
.comfortaa .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount .separator:after strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount input,
.omnes .load-receipt-invoice .yxp-rw-product-wrapper .yxp-rw-product .singleProductAmount .separator:after {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}

.yxp-modal .yxpMultipleInputWrapper,
.load-receipt-invoice .yxpMultipleInputWrapper {
  border-bottom: 2px solid;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.yxp-modal .yxpMultipleInputWrapper.no-decimal .yxpInputGroupElement:first-child,
.load-receipt-invoice .yxpMultipleInputWrapper.no-decimal .yxpInputGroupElement:first-child {
  text-align: left;
}
.yxp-modal .yxpMultipleInputWrapper.no-decimal .separator,
.yxp-modal .yxpMultipleInputWrapper.no-decimal .yxpInputGroupElement:last-child,
.load-receipt-invoice .yxpMultipleInputWrapper.no-decimal .separator,
.load-receipt-invoice .yxpMultipleInputWrapper.no-decimal .yxpInputGroupElement:last-child {
  display: none;
}
.yxp-modal .yxpMultipleInputWrapper .yxpInputGroupElement,
.load-receipt-invoice .yxpMultipleInputWrapper .yxpInputGroupElement {
  border: none;
}
.yxp-modal .yxpMultipleInputWrapper .yxpInputGroupElement:first-child,
.load-receipt-invoice .yxpMultipleInputWrapper .yxpInputGroupElement:first-child {
  width: 66px;
  text-align: right;
}
.yxp-modal .error-msg,
.yxp-modal .error-feedback,
.load-receipt-invoice .error-msg,
.load-receipt-invoice .error-feedback {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #DE0000;
}
.yxp-modal .error-msg b, .yxp-modal .error-msg strong,
.yxp-modal .error-feedback b,
.yxp-modal .error-feedback strong,
.load-receipt-invoice .error-msg b,
.load-receipt-invoice .error-msg strong,
.load-receipt-invoice .error-feedback b,
.load-receipt-invoice .error-feedback strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxp-modal .error-msg,
.comfortaa .yxp-modal .error-feedback,
.comfortaa .load-receipt-invoice .error-msg,
.comfortaa .load-receipt-invoice .error-feedback {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .yxp-modal .error-msg b, .comfortaa .yxp-modal .error-msg strong,
.comfortaa .yxp-modal .error-feedback b,
.comfortaa .yxp-modal .error-feedback strong,
.comfortaa .load-receipt-invoice .error-msg b,
.comfortaa .load-receipt-invoice .error-msg strong,
.comfortaa .load-receipt-invoice .error-feedback b,
.comfortaa .load-receipt-invoice .error-feedback strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .yxp-modal .error-msg,
.omnes .yxp-modal .error-feedback,
.omnes .load-receipt-invoice .error-msg,
.omnes .load-receipt-invoice .error-feedback {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}
.yxp-modal .error-feedback,
.load-receipt-invoice .error-feedback {
  padding-top: 8px;
}
.yxp-modal .complete-error,
.load-receipt-invoice .complete-error {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #DE0000;
  margin-top: 24px;
}
.yxp-modal .complete-error b, .yxp-modal .complete-error strong,
.load-receipt-invoice .complete-error b,
.load-receipt-invoice .complete-error strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxp-modal .complete-error,
.comfortaa .load-receipt-invoice .complete-error {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .yxp-modal .complete-error b, .comfortaa .yxp-modal .complete-error strong,
.comfortaa .load-receipt-invoice .complete-error b,
.comfortaa .load-receipt-invoice .complete-error strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.yxp-modal .complete-error i,
.load-receipt-invoice .complete-error i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
@media (min-width: 768px) {
  .yxp-modal .complete-error,
  .load-receipt-invoice .complete-error {
    font-size: 20px;
    line-height: 24px;
  }
}
.yxp-modal .separator,
.load-receipt-invoice .separator {
  color: inherit;
  padding: 0 5px;
}
html[lang=en] .yxp-modal .separator,
html[lang=en] .load-receipt-invoice .separator {
  font-size: 0;
  line-height: 0;
}
html[lang=en] .yxp-modal .separator:after,
html[lang=en] .load-receipt-invoice .separator:after {
  content: ".";
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
}
html[lang=en] .yxp-modal .separator:after b, html[lang=en] .yxp-modal .separator:after strong,
html[lang=en] .load-receipt-invoice .separator:after b,
html[lang=en] .load-receipt-invoice .separator:after strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa html[lang=en] .yxp-modal .separator:after,
.comfortaa html[lang=en] .load-receipt-invoice .separator:after {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa html[lang=en] .yxp-modal .separator:after b, .comfortaa html[lang=en] .yxp-modal .separator:after strong,
.comfortaa html[lang=en] .load-receipt-invoice .separator:after b,
.comfortaa html[lang=en] .load-receipt-invoice .separator:after strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  html[lang=en] .yxp-modal .separator:after,
  html[lang=en] .load-receipt-invoice .separator:after {
    font-size: 21px;
    line-height: 28px;
  }
}

.yxp-modal .yxp-modal-overlay {
  background: rgba(38, 51, 126, 0.4);
}
.yxp-modal .currency-symbol {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding: 0 10px;
  color: #999999;
}
.yxp-modal .currency-symbol b, .yxp-modal .currency-symbol strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxp-modal .currency-symbol {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .yxp-modal .currency-symbol b, .comfortaa .yxp-modal .currency-symbol strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .yxp-modal .currency-symbol {
    font-size: 21px;
    line-height: 28px;
  }
}
.yxp-modal .yxp-modal-card {
  text-align: left;
  border-radius: 8px;
  padding: 32px 24px;
  width: 100%;
  max-height: 100vh;
  max-width: 800px;
  overflow: auto;
}
@media (min-width: 768px) {
  .yxp-modal .yxp-modal-card {
    overflow: visible;
    padding: 56px;
  }
}
.yxp-modal .yxp-modal-card .yxpInputGroup {
  padding-bottom: 32px;
}
.yxp-modal .yxp-modal-title {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 16px;
  text-align: center;
}
.yxp-modal .yxp-modal-title b, .yxp-modal .yxp-modal-title strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxp-modal .yxp-modal-title {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa .yxp-modal .yxp-modal-title b, .comfortaa .yxp-modal .yxp-modal-title strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.yxp-modal .yxp-modal-title i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold-italic", sans-serif;
  font-size: 32px;
  line-height: 34px;
}
@media (min-width: 768px) {
  .yxp-modal .yxp-modal-title {
    font-size: 40px;
    line-height: 46px;
  }
  .yxp-modal .yxp-modal-title i {
    font-size: 44px;
    line-height: 46px;
  }
}
.nexa .yxp-modal .yxp-modal-title {
  font-weight: normal;
  font-style: normal;
  font-family: "Nexa", sans-serif;
  line-height: 26px;
  font-size: 22px;
}
.yxp-modal .yxp-modal-footer {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 32px;
}
.yxp-modal .yxp-modal-button.save {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #F47920;
  font-size: 16px;
  color: white;
  border: none;
  height: 55px;
  margin: 4px 12px;
}
.comfortaa .yxp-modal .yxp-modal-button.save {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.yxp-modal .yxp-modal-button.cancel {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  background: white;
  color: #F47920;
  border: none;
  height: 55px;
  margin: 4px 12px;
  border: 1px solid;
}
.comfortaa .yxp-modal .yxp-modal-button.cancel {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.yxp-modal .yxp-custom-select-clear {
  font-size: 0;
  padding: 14px 3px;
}
.yxp-modal .yxp-custom-select-clear::before {
  color: #03499A;
  content: "\e905";
  font-family: "sofidel" !important;
  font-size: 24px;
}
.black-color .yxp-modal .yxp-custom-select-clear::before {
  color: #272727;
}
.orange-theme .yxp-modal .yxp-custom-select-clear::before {
  color: #F47920;
}
.yxp-modal input.yxp-custom-select-search {
  width: -webkit-calc(100% - 32px);
  width: calc(100% - 32px);
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
}
.yxp-modal input.yxp-custom-select-search b, .yxp-modal input.yxp-custom-select-search strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxp-modal input.yxp-custom-select-search {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .yxp-modal input.yxp-custom-select-search b, .comfortaa .yxp-modal input.yxp-custom-select-search strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .yxp-modal input.yxp-custom-select-search {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
.yxp-modal .yxp-custom-select-options {
  max-height: 300px;
  overflow: auto;
}
.yxp-modal .yxp-custom-select-option {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
}
.yxp-modal .yxp-custom-select-option b, .yxp-modal .yxp-custom-select-option strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxp-modal .yxp-custom-select-option {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .yxp-modal .yxp-custom-select-option b, .comfortaa .yxp-modal .yxp-custom-select-option strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .yxp-modal .yxp-custom-select-option {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
.yxp-modal .yxp-modal-card-modifier-wrapper {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.yxp-modal .yxp-modal-card-modifier-wrapper a.modifier {
  color: #03499A;
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid rgba(38, 51, 126, 0.2);
  width: 48px;
  height: 48px;
  text-decoration: none !important;
}
.black-color .yxp-modal .yxp-modal-card-modifier-wrapper a.modifier {
  color: #272727;
}
.orange-theme .yxp-modal .yxp-modal-card-modifier-wrapper a.modifier {
  color: #F47920;
}
.yxp-modal .yxp-modal-card-modifier-wrapper a.modifier b, .yxp-modal .yxp-modal-card-modifier-wrapper a.modifier strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxp-modal .yxp-modal-card-modifier-wrapper a.modifier {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .yxp-modal .yxp-modal-card-modifier-wrapper a.modifier b, .comfortaa .yxp-modal .yxp-modal-card-modifier-wrapper a.modifier strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.yxp-modal .yxp-modal-card-modifier-wrapper a.modifier i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}
@media (min-width: 768px) {
  .yxp-modal .yxp-modal-card-modifier-wrapper a.modifier {
    font-size: 20px;
    line-height: 24px;
  }
}
.yxp-modal .yxp-modal-card-modifier-wrapper .value {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #26337E;
  min-width: 100px;
  margin: 0 16px;
  text-align: center;
}
.yxp-modal .yxp-modal-card-modifier-wrapper .value b, .yxp-modal .yxp-modal-card-modifier-wrapper .value strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .yxp-modal .yxp-modal-card-modifier-wrapper .value {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa .yxp-modal .yxp-modal-card-modifier-wrapper .value b, .comfortaa .yxp-modal .yxp-modal-card-modifier-wrapper .value strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .yxp-modal .yxp-modal-card-modifier-wrapper .value {
    font-size: 21px;
    line-height: 28px;
  }
}
.yxp-modal .yxp-modal-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.yxp-modal .yxp-modal-content .yxpInputGroup {
  width: 100%;
}
@media (min-width: 768px) {
  .yxp-modal .yxp-modal-content .yxpInputGroup.quantityInputGroup, .yxp-modal .yxp-modal-content .yxpInputGroup.amountInputGroup {
    width: 48%;
  }
}

.enter-to-win {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(-50%, #1C93DC), color-stop(40%, rgba(1, 233, 189, 0.5)), color-stop(80%, white));
  background: linear-gradient(0deg, #1C93DC -50%, rgba(1, 233, 189, 0.5) 40%, white 80%);
  min-height: -webkit-calc(100vh - 240px);
  min-height: calc(100vh - 240px);
}
.enter-to-win .generic-card {
  max-width: -webkit-calc(100% - 40px);
  max-width: calc(100% - 40px);
  margin: 32px 20px;
  padding: 32px;
}
@media (min-width: 768px) {
  .enter-to-win .generic-card {
    max-width: -webkit-calc(50% - 40px);
    max-width: calc(50% - 40px);
  }
}
.enter-to-win .yxpType_TITLE {
  min-height: 80px;
}
@media (min-width: 768px) {
  .enter-to-win .yxpType_TITLE {
    min-height: 120px;
  }
}

.instant-win {
  padding-bottom: 32px;
}
.instant-win h1,
.instant-win h3 {
  margin-bottom: 24px;
}
.instant-win__description {
  max-width: 800px;
  margin: 0 auto;
}
.instant-win .yxpType_LINK {
  text-align: left;
  border-radius: 8px;
  padding: 16px !important;
  border: 1px solid rgba(38, 51, 126, 0.2);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background: white;
  max-width: 800px;
  width: -webkit-calc(100% - 40px);
  width: calc(100% - 40px);
  padding: 16px !important;
  margin: 0 auto 16px;
}
.instant-win .yxpType_LINK a,
.instant-win .yxpType_LINK .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #26337E;
  text-decoration: none;
  cursor: default;
  margin: 0;
}
.instant-win .yxpType_LINK a b, .instant-win .yxpType_LINK a strong,
.instant-win .yxpType_LINK .yxpHtmlTextWrapper b,
.instant-win .yxpType_LINK .yxpHtmlTextWrapper strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .instant-win .yxpType_LINK a,
.comfortaa .instant-win .yxpType_LINK .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-regular", sans-serif;
}
.comfortaa .instant-win .yxpType_LINK a b, .comfortaa .instant-win .yxpType_LINK a strong,
.comfortaa .instant-win .yxpType_LINK .yxpHtmlTextWrapper b,
.comfortaa .instant-win .yxpType_LINK .yxpHtmlTextWrapper strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .instant-win .yxpType_LINK a,
.omnes .instant-win .yxpType_LINK .yxpHtmlTextWrapper {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-regular", sans-serif;
}
.instant-win .yxpType_LINK:before {
  content: "\e90d";
  font-family: "sofidel" !important;
  font-size: 40px;
  color: #F47920;
  margin-right: 16px;
}
.instant-win .yxpType_IMAGE {
  width: 100%;
  padding: 0 20px;
  max-width: 520px;
  margin: 0 auto;
  text-align: center;
}
.instant-win__prize {
  margin: 56px auto 0;
  max-width: 800px;
  color: rgba(38, 51, 126, 0.5);
  text-align: center;
}
.instant-win__image {
  width: 340px;
  height: 340px;
  margin: 0 auto 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: url("/fe-web/img/sofidel/backgrounds/circle-gradient.png") no-repeat top;
  background-size: 80% auto;
}
@media (min-width: 768px) {
  .instant-win__image {
    background-size: contain;
    padding: 20px;
  }
}
.instant-win__prize-name {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #26337E;
}
.instant-win__prize-name b, .instant-win__prize-name strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .instant-win__prize-name {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa .instant-win__prize-name b, .comfortaa .instant-win__prize-name strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .instant-win__prize-name {
    font-size: 21px;
    line-height: 28px;
  }
}
.instant-win__disclaimer {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  text-align: center;
}
.instant-win__disclaimer b, .instant-win__disclaimer strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .instant-win__disclaimer {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa .instant-win__disclaimer b, .comfortaa .instant-win__disclaimer strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .instant-win__disclaimer {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}

.minimal-template .reward-request-form {
  margin-top: 260px;
}
.reward-request-form .reward-ok {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.reward-request-form .reward-ok h4 {
  margin: 24px 0;
}
.reward-request-form .primary-button {
  margin-top: 32px;
}
.reward-request-form .primary-button a {
  color: white;
}
.reward-request-form .yxpType_UPLOAD_INPUT input::file-selector-button {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  line-height: 20px;
  background: #26337E;
  border-radius: 30px;
  padding: 10px 16px;
  margin-right: 8px;
  color: white;
  border: none;
}
.reward-request-form .yxpType_UPLOAD_INPUT input::file-selector-button b, .reward-request-form .yxpType_UPLOAD_INPUT input::file-selector-button strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .reward-request-form .yxpType_UPLOAD_INPUT input::file-selector-button {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.comfortaa .reward-request-form .yxpType_UPLOAD_INPUT input::file-selector-button b, .comfortaa .reward-request-form .yxpType_UPLOAD_INPUT input::file-selector-button strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .reward-request-form .yxpType_UPLOAD_INPUT input::file-selector-button {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
}

.ty-receipt-invoice__header {
  padding: 150px 20px 200px;
  background: linear-gradient(105.44deg, #1C93DC -3.6%, #01E9BD 88.54%);
  padding-top: 100px;
  position: relative;
  color: white;
}
@media (min-width: 768px) {
  .ty-receipt-invoice__header {
    padding-top: 150px !important;
  }
}
.ty-receipt-invoice__header:before {
  content: "";
  position: absolute;
  background: url("/fe-web/img/sofidel/backgrounds/white-wave.png") no-repeat top;
  background-size: cover;
  height: 30px;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-transform: rotate(180deg) translateY(100%);
          transform: rotate(180deg) translateY(100%);
}
@media (min-width: 768px) {
  .ty-receipt-invoice__header:before {
    background-size: contain;
    height: 4vw;
  }
}
.ty-receipt-invoice__header.flipped:before {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.ty-receipt-invoice__header:before {
  top: 100%;
}
.ty-receipt-invoice__card {
  background: white;
  border: 1px solid rgba(38, 51, 126, 0.1);
  -webkit-box-shadow: 0 8px 40px rgba(39, 39, 39, 0.1);
          box-shadow: 0 8px 40px rgba(39, 39, 39, 0.1);
  border-radius: 8px;
  text-align: center;
  padding: 16px 24px;
  max-width: 800px;
  width: -webkit-calc(100% - 40px);
  width: calc(100% - 40px);
  margin: -130px auto 20px;
  position: relative;
  padding: 32px;
}
@media (min-width: 768px) {
  .ty-receipt-invoice__card {
    margin-top: -90px;
  }
}
.ty-receipt-invoice img {
  width: 60px;
}
.ty-receipt-invoice__label {
  display: block;
  margin: 16px;
}
.ty-receipt-invoice h3 {
  max-width: 600px;
  margin: 45px auto;
  padding: 0 20px;
}
.ty-receipt-invoice__points {
  background: rgba(244, 121, 32, 0.1);
  border-radius: 8px;
  padding: 8px 0 8px 8px;
}
.ty-receipt-invoice__total {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-medium", sans-serif;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 20px;
  gap: 8px;
}
.ty-receipt-invoice__total b, .ty-receipt-invoice__total strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-black", sans-serif;
}
.comfortaa .ty-receipt-invoice__total {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-medium", sans-serif;
}
.comfortaa .ty-receipt-invoice__total b, .comfortaa .ty-receipt-invoice__total strong {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
.omnes .ty-receipt-invoice__total {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-medium", sans-serif;
}
.ty-receipt-invoice__total i {
  font-weight: normal;
  font-style: normal;
  font-family: "Omnes-bold", sans-serif;
  font-size: 32px;
  color: #F47920;
}
.ty-receipt-invoice__total:after {
  content: "";
  width: 28px;
  height: 28px;
  background: url("/fe-web/img/sofidel/icons/point-outline.png") no-repeat top;
  background-size: contain;
}
.ty-receipt-invoice__button {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  background: #F47920;
  font-size: 16px;
  color: white;
  border: none;
  height: 65px;
  margin-bottom: 80px;
}
.comfortaa .ty-receipt-invoice__button {
  font-weight: normal;
  font-style: normal;
  font-family: "Comfortaa-bold", sans-serif;
}
@media (min-width: 768px) {
  .ty-receipt-invoice__button {
    font-size: 21px;
  }
}